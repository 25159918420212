import { useFormContext } from "react-hook-form";
import { createRef, useEffect, useState } from "react";
import MLTextField from "common/ml-components/inputs/MLTextField";
import MLDropdown, { DropdownStyles } from "common/ml-components/inputs/MLDropdown";
import MLCollection from "common/ml-components/MLList/MLCollection/MLCollection";
import MLNewItem from "common/ml-components/MLList/MLNewItem/MLNewItem";

interface IQuizLessonProps {}

const QuizLesson: React.FC<IQuizLessonProps> = () => {
  const { getValues, setValue, watch } = useFormContext();

  const fields = getValues('lesson.options');
  const [options, setOptions] =
    useState<{ id: string | number; value: string; ref: React.RefObject<HTMLDivElement> }[]>(() => (
      fields.map((field: { id: string | number; value: string; }) => ({
        id: field.id,
        value: field.value,
        ref: createRef<HTMLDivElement>(),
      }))
    ));

  const correctAnswerId = watch('lesson.correctAnswerId');

  useEffect(() => {
    setValue('lesson.options', options.map(opt => ({ id: opt.id, value: opt.value })), { shouldDirty: true });
  }, [options, setValue]);

  return (
    <>
      <MLTextField
        name="lesson.title"
        label="Title"
        multiline
        autoAdjustHeight
        resizable={false}
      />
      <MLTextField
        name="lesson.question"
        label="Question"
        multiline
        autoAdjustHeight
        resizable={false}
      />
      <MLCollection
        items={options}
        setItems={setOptions}
        title="Answers"
        disabledIndex={options.findIndex((opt: any) => opt.id === correctAnswerId)}
        draggable
      />
      <MLNewItem
        items={options}
        setItems={setOptions}
        idNumber
      />
      <MLDropdown
        name='lesson.correctAnswerId'
        label="Correct answer"
        dropdownWidth={300}
        dropdownStyle={DropdownStyles.COLORED}
        options={options.map((option: any) => ({ key: option.id, text: option.value }))}
      />
        <MLTextField
          name="lesson.explanationCorrectAnswer"
          label='Correct answer explanation'
          multiline
          autoAdjustHeight
          resizable={false}
        />
    </>
  );
};

export default QuizLesson;
