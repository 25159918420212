import { useFormContext } from "react-hook-form";
import { createRef, useEffect, useState } from "react";
import MLTextField from "common/ml-components/inputs/MLTextField";
import MLCollection from "common/ml-components/MLList/MLCollection/MLCollection";
import MLNewItem from "common/ml-components/MLList/MLNewItem/MLNewItem";

interface IBulletPointsLessonProps {}

const BulletPointsLesson: React.FC<IBulletPointsLessonProps> = () => {
  const { getValues, setValue } = useFormContext();

  const fields = getValues('lesson.bulletPoints');
  const [bulletPoints, setBulletPoints] = 
    useState<{ id: string | number; value: string; ref: React.RefObject<HTMLDivElement> }[]>(() => (
      fields.map((field: { id: string | number; value: string; }) => ({
        id: field.id,
        value: field.value,
        ref: createRef<HTMLDivElement>(),
      }))
    ));

  useEffect(() => {
    setValue('lesson.bulletPoints', bulletPoints.map(bp => ({ id: bp.id, value: bp.value })), { shouldDirty: true });
  }, [bulletPoints, setValue]);

  return (
    <>
      <MLTextField
        name="lesson.title"
        label="Title"
        multiline
        autoAdjustHeight
        resizable={false}
      />
      <MLTextField
        name="lesson.introduction"
        label="Introduction"
        multiline
        autoAdjustHeight
        resizable={false}
      />
      <MLCollection
        items={bulletPoints}
        setItems={setBulletPoints}
        title="Bullet points"
        draggable
      />
      <MLNewItem
        items={bulletPoints}
        setItems={setBulletPoints}
      />
    </>
  );
};

export default BulletPointsLesson;
