import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { getClassNames } from "./Attachment.classNames";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { AxiosError } from "axios";
import { useParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { Icon, Spinner, SpinnerSize } from "@fluentui/react";
import { IAttachment } from "common/models/Learnings/attachment";
import { MIMETypes } from "utils/constants/file-types";
import VideoPlayer from "./components/VideoPlayer/VideoPlayer";
import { fileToBase64, isFileTypeCorrect } from "utils/helpers/helpers";
import ImageC from "./components/Image/Image";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { ANIMATION_DURATION } from "utils/constants/styles";
import AddAttachment from "./components/AddAttachment/AddAttachment";
import EmbeddedContent from './components/EmbeddedContent/EmbeddedContent';

const Attachment: React.FC = () => {
  const { coursesClientApi } = useSelector((state: RootState) => state.clients.clients);
  const courseId = useSelector((state: RootState) => state.courses.course?.id);
  const lessonId = useSelector((state: RootState) => state.courses.lessonToEdit?.id);
  const documentLanguage = useSelector((state: RootState) => state.courses.course?.documentLanguage);
  const { lang: currentLanguage } = useParams();
  const { watch, setValue } = useFormContext();

  const attachment: IAttachment = watch('attachment');

  const [isLoading, setIsLoading] = useState<boolean>(attachment === undefined);
  const loadingRef = useRef<HTMLDivElement>(null);
  const attachmentRef = useRef<HTMLDivElement>(null);
  const addAttachmentRef = useRef<HTMLDivElement>(null);
  const inputFileRef = useRef<HTMLInputElement>(null);

  const loadAttachment = useCallback(async () => {
    if (!courseId || !documentLanguage || !lessonId || !coursesClientApi) return;

    try {
      const attachment = await coursesClientApi.getLessonAttachment(courseId, currentLanguage || documentLanguage, lessonId);
      console.log('attachment', attachment);
      if (attachment.data.contentType === 'embedding') {
        if (!attachment.data.data || !attachment.data.contentType) return;
        setValue('attachment', {data: attachment.data.data, src: ``, contentType: attachment.data.contentType, originalData: attachment.data.data});
      } else {
        if (!attachment.data.data || !attachment.data.contentType) return;
        if (attachment.data.contentType === 'video/mp4') {
          setValue('attachment', { data: attachment.data.data, src: `data:${attachment.data.contentType};base64,${attachment.data.data}`, contentType: attachment.data.contentType, originalData: attachment.data.data });
          return;
        };
        const img = new Image();
        img.onload = () => {
          setValue('attachment', { data: attachment.data.data, src: `data:${attachment.data.contentType};base64,${attachment.data.data}`, contentType: attachment.data.contentType, originalData: attachment.data.data });
        };
        img.src = `data:${attachment.data.contentType};base64,${attachment.data.data}`;
      }
    } catch (err) {
      const axiosError = err as AxiosError;
      setValue('attachment', { data: '', src: '', contentType: '', originalData: '' });
      console.error(axiosError.response?.status);
    } finally {
      setIsLoading(false);
    }
  }, [courseId, documentLanguage, currentLanguage, lessonId, coursesClientApi, setValue]);

  const onFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (isFileTypeCorrect(e.target.files[0])) {
        const chosenFile = await fileToBase64(e.target.files[0]);
        if (chosenFile) {
          const blob = new Blob([e.target.files[0]]);
          const blobUrl = URL.createObjectURL(blob);
          if (e.target.files[0].type.includes('image')) {
            const img = new Image();
            img.onload = () => {
              if (!e.target?.files) return;
              setValue('attachment', { data: chosenFile.slice(chosenFile.indexOf(',') + 1), src: blobUrl, contentType: e.target.files[0].type, originalData: attachment.originalData }, { shouldDirty: true });
            };
            img.src = blobUrl;
          }

          if (e.target.files[0].type.includes('video')) {
            console.log('e.target.files[0]', e.target.files[0]);
            setValue('attachment', { data: chosenFile.slice(chosenFile.indexOf(',') + 1), src: blobUrl, contentType: e.target.files[0].type, originalData: attachment.originalData }, { shouldDirty: true });
          } 
        }
      }
    }
  };

  const clearAttachment = () => {
    if (inputFileRef.current && inputFileRef.current.files) {
      inputFileRef.current.files = null;
      inputFileRef.current.value = '';
    }
    setValue('attachment', { data: '', src: '', contentType: '', originalData: attachment.originalData }, { shouldDirty: true });
  };

  const contentRef = isLoading ? loadingRef : attachment?.data ? attachmentRef : addAttachmentRef;

  useEffect(() => {
    if (attachment === undefined) loadAttachment();
  }, [loadAttachment, attachment]);

  const classes = getClassNames();

  return (
    <SwitchTransition>
      <CSSTransition
        key={isLoading ? "spinner" : attachment?.contentType ? "media" : "addAttachment"}
        nodeRef={contentRef}
        timeout={ANIMATION_DURATION}
        classNames={{
          enter: classes.contentEnter,
          enterActive: classes.contentEnterActive,
          exit: classes.contentExit,
          exitActive: classes.contentExitActive,
        }}
      >
        <div ref={contentRef} className={classes.attachmentContainer}>
          {isLoading && <Spinner size={SpinnerSize.large} />}
          {attachment?.data && <Icon className={classes.closeIcon} iconName="ChromeClose" onClick={clearAttachment} />}
          {(attachment?.contentType === MIMETypes.JPEG ||
            attachment?.contentType === MIMETypes.PNG) && (
              <ImageC src={attachment.src ? attachment.src : ''}/>
            )
          }
          {attachment?.contentType === MIMETypes.MP4 && (
            <VideoPlayer attachment={attachment} />
          )}
          {attachment?.contentType === 'embedding' && attachment.data && (
            <EmbeddedContent src={attachment.data as string} />
          )}
          {!attachment?.data && !isLoading && (
            <AddAttachment
              inputFileRef={inputFileRef}
              onFileChange={onFileChange}
            />
          )}
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default Attachment;