import { AsyncThunk, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { CoursesMapper } from '../../utils/mappers/courses';
import { BulletPointLessonDto, CourseStatusDto, FinalQuizLessonDto, GenerateLessonRequestDto, LessonDto, LessonTypeDto, QuizLessonDto, ScheduleDto, ScheduleStatusDto, SupportedLanguageDto, TextLessonDto, TranslateRequestDto, GlobalCourseSettingsDto, GenerateRequestDto, ScratchCourseDto, IdentityTypeDto, GamificationInfoDto } from '../../api-client';
import { RootState } from '../store';
import { DateTime } from 'luxon';
import { IAttachment } from 'common/models/Learnings/attachment';
import axios, { AxiosError } from 'axios';
import { ApplicationSettings } from '../../utils/settings/application';
import { ApiStatus } from 'common/models/store/api-status';
import { IQuizOptionsFormData } from 'pages/Learnings/Learning/components/Forms/CourseConfigurations/CourseConfigurations';
import { ICourse } from 'common/models/ICourse';
import { IUser } from 'common/models/IUser';

export interface ICoursesState {
  courses: ICourse[] | undefined;
  course: ICourse | undefined;
  lessonToEdit: LessonDto | undefined;
  lessonToDelete: LessonDto | undefined;
  apiStatus: {
    status: ApiStatus;
    errorMessage: string;
  };
}

export const generateCourse = createAsyncThunk<void, GenerateRequestDto>('/courses/generateCourse', async (body, thunkAPI) => {
  try {
    const { coursesFunctionClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesFunctionClientApi) throw new Error();

    await coursesFunctionClientApi.generateCourse(body);
  } catch (err) {
    console.error(err);
    if (err instanceof AxiosError) {
      return thunkAPI.rejectWithValue(err.response?.data.message);
    } else return thunkAPI.rejectWithValue(err instanceof Error ? err.message : 'Generic error');
  }
}, {
  getPendingMeta: () => ({
    apiStatus: ApiStatus.SAVING
  })
});

export const createFromScratch = createAsyncThunk<void, ScratchCourseDto>('/courses/create/from-scratch', async (body, thunkAPI) => {
  try {
    const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesClientApi) throw new Error();

    await coursesClientApi.addCourseFromScratch(body);
  } catch (err) {
    console.error(err);
    if (err instanceof AxiosError) {
      return thunkAPI.rejectWithValue(err.response?.data.Detail);
    } else return thunkAPI.rejectWithValue(err instanceof Error ? err.message : 'Generic error');
  }
}, {
  getPendingMeta: () => ({
    apiStatus: ApiStatus.SAVING
  })
});

export const getCourses = createAsyncThunk<ICourse[], void>('/courses/getCourses', async (_, thunkAPI) => {
  try {
    const { coursesClientApi, usersClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesClientApi || !usersClientApi) throw new Error();

    // fetching the courses
    const courseResponse = await coursesClientApi.getCourses();
    const courses = courseResponse.data;

    // fetching the authors
    const uniqueUsersPromises: any = [];
    const uniqueUsers: IUser[] = courses.filter((value, index, self) => 
      index === self.findIndex((t) => (
          t.createdBy === value.createdBy
      ))
    ).map(el => ({
      id: '', 
      username: el.createdBy || '',
      displayName: '',
      profilePicture: '',
      type: IdentityTypeDto.User,
    }));

    uniqueUsers.forEach(el => uniqueUsersPromises.push(usersClientApi.apiTenantUsersUpnOrIdGet(el.username)));
    const userResponse = await Promise.allSettled(uniqueUsersPromises);

    const usersWithoutPhotos: IUser[] = userResponse.map((result, index) => {
      if (result.status === "fulfilled") {
        return {...result.value.data, username: uniqueUsers[index].username};
      } else {
        console.warn(`Failed to fetch data for author: ${uniqueUsers[index].username}`);
        return {
          username: uniqueUsers[index].username,
        }
      }
    });

    const usersWithoutPhotosPromises: any = [];
    usersWithoutPhotos.forEach(user => usersWithoutPhotosPromises.push(usersClientApi.apiTenantUsersUpnOrIdPhotoGet(user.username)));
    const photoResponses = await Promise.allSettled(usersWithoutPhotosPromises);
    
    const users: IUser[] = photoResponses.map((result, index) => {
      if (result.status === "fulfilled") {
        return {
          ...usersWithoutPhotos[index],
          profilePicture: result.value.data,
        };
      } else {
        console.warn(`Failed to fetch data for author: ${uniqueUsers[index].username}`);
        return {
          ...usersWithoutPhotos[index],
        }
      }
    });

    // mapping and returning the courses
    return CoursesMapper(courses, users);
  } catch (err) {
    console.error(err);
    throw err;
  }
}, {
  getPendingMeta: () => ({
    apiStatus: ApiStatus.LOADING
  })
});

export const getCourse = createAsyncThunk<ICourse, {id: string, lang: string | undefined}>('/course', async ({ id, lang }, thunkAPI) => {
  try {
    const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesClientApi) throw new Error();
    
    const response = lang ? await coursesClientApi.getCourseByLang(id, lang) : await coursesClientApi.getCourse(id);
    if (!response.data.statusInfo) response.data.statusInfo = { justGenerated: false };
    return response.data as ICourse;
  } catch (err) {
    console.error(err);
    throw err;
  }
}, {
  getPendingMeta: () => ({
    apiStatus: ApiStatus.LOADING
  })
});

export const getCourseAfterGeneration = createAsyncThunk<ICourse, {id: string | undefined}>('/course/afterGeneration', async ({ id }, thunkAPI) => {
  try {
    const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesClientApi) throw new Error();
    
    if (!id) throw new Error();

    const response = await coursesClientApi.getCourse(id);
    if (!response.data.statusInfo) response.data.statusInfo = { justGenerated: false };
    return response.data as ICourse;
  } catch (err) {
    console.error(err);
    throw err;
  }
}, {
  getPendingMeta: () => ({
    apiStatus: ApiStatus.IDLE
  })
});

// export const toggleHide = 
//   createAsyncThunk<LessonDto, {lesson: LessonDto, lang: string | undefined}>('/toggleHide', async ({ lesson, lang }, thunkAPI) => {
//     if (!lesson || !lesson.id) throw new Error();

//     const { course } = (thunkAPI.getState() as RootState).courses;
//       if (!course || !course.id || !course.documentLanguage || !course.lessons) throw new Error();

//     try {
//       const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
//       if (!coursesClientApi) throw new Error();

//       lesson.isHide ? await coursesClientApi.showLesson(course.id, lesson.id, lang || course.documentLanguage) :
//         await coursesClientApi.hideLesson(course.id, lesson.id, lang || course.documentLanguage);

//       const newLesson = {...lesson};
//       newLesson.isHide = !newLesson.isHide;
//       return newLesson;
//     } catch (err) {
//       console.error(err);
//       throw err;
//     }
//   });

  export const deleteLesson = 
  createAsyncThunk<LessonDto[], {lang: string | undefined}>('/courses/deleteLesson', async ({ lang }, thunkAPI) => {
    const { course, lessonToDelete } = (thunkAPI.getState() as RootState).courses;
    if (!course || !course.id || !course.documentLanguage || !course.lessons) throw new Error();
    if (!lessonToDelete || !lessonToDelete.id) throw new Error();

    try {
      const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
      if (!coursesClientApi) throw new Error();

      await coursesClientApi.deleteLesson(course.id, lessonToDelete.id, lang || course.documentLanguage);

      const newLessons = [...course.lessons];
      const lessonIndex = newLessons.findIndex(el => el.id === lessonToDelete.id);
      if (lessonIndex === -1) throw new Error();
      newLessons.splice(lessonIndex, 1);
      return newLessons;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }, {
    getPendingMeta: () => ({
      apiStatus: ApiStatus.SAVING
    })
  });

export const changeLessonOrder = createAsyncThunk<void, { lang: string | undefined }>('/changeLessonOrder',
  async ({ lang }, thunkAPI) => {
    const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesClientApi) throw new Error();

    const { course } = (thunkAPI.getState() as RootState).courses;
    if (!course || !course.id || !course.lessons) return;

    const newLessons = [...course.lessons];

    try {
      await coursesClientApi.changeOrderByLang(course.id, lang || course.documentLanguage || '', newLessons.map(lesson => lesson.id || ''));
    } catch (err) {
      console.error(err);
      throw err;
    }
}, {
  getPendingMeta: () => ({
    apiStatus: ApiStatus.SAVING
  })
});

export const publishCourse = createAsyncThunk<void, string | undefined | null>('/courses/publishCourse', async (learningProviderId, thunkAPI) => {
  try {
    const { course } = (thunkAPI.getState() as RootState).courses;
    if (!course || !course.id || !course.lessons) throw new Error();

    const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesClientApi) return;

    if (!learningProviderId) await coursesClientApi.publishCourseNoProvider(course.id);
    else await coursesClientApi.publishCourse(course.id, learningProviderId);
  } catch (err) {
    console.error(err);
    throw err;
  }
}, {
  getPendingMeta: () => ({
    apiStatus: ApiStatus.SAVING
  })
});

export const unpublishCourse = createAsyncThunk<void, string | undefined | null>('/courses/unpublishCourse', async (learningProviderId, thunkAPI) => {
  try {
    const { course } = (thunkAPI.getState() as RootState).courses;
    if (!course || !course.id || !course.lessons) throw new Error();

    const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesClientApi) throw new Error();

    if (!learningProviderId) await coursesClientApi.unpublishCourseNoProvider(course.id);
    else await coursesClientApi.unpublishCourse(course.id, learningProviderId);
  } catch (err) {
    console.error(err);
    throw err;
  }
}, {
  getPendingMeta: () => ({
    apiStatus: ApiStatus.SAVING
  })
});

export const deleteCourse = createAsyncThunk<void, void>('/courses/deleteCourse', async (_, thunkAPI) => {
  try {
    const { course } = (thunkAPI.getState() as RootState).courses;
    if (!course || !course.id) throw new Error();

    const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesClientApi) throw new Error();

    await coursesClientApi.archiveCourse(course.id);
  } catch (err) {
    console.error(err);
    throw err;
  }
}, {
  getPendingMeta: () => ({
    apiStatus: ApiStatus.SAVING
  })
});

export const deleteCourseInError = createAsyncThunk<ICourse[], { courseIdToDelete: string }>('/courses/deleteCourseInError', async ({ courseIdToDelete }, thunkAPI) => {
  try {
    const courses = (thunkAPI.getState() as RootState).courses.courses;
    if (!courses) throw new Error();
    const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesClientApi) throw new Error();

    await coursesClientApi.archiveCourse(courseIdToDelete);

    const newCourses = [...courses];
    const courseIndexToDelete = newCourses.findIndex(course => course.id === courseIdToDelete);
    if (courseIndexToDelete === -1) throw new Error();
    newCourses.splice(courseIndexToDelete, 1);
    return newCourses;
  } catch (err) {
    console.error(err);
    throw err;
  }
}, {
  getPendingMeta: () => ({
    apiStatus: ApiStatus.SAVING
  })
});

export const saveCourseOptions = createAsyncThunk<{
  courseOptions: {
    expirationDate: string | null;
    validFromDate: string | null;
    title: string;
    description: string;
    isSearchEnabled: boolean;
  },
  quizOptions: IQuizOptionsFormData,
  gamification: GamificationInfoDto 
}, {
  courseOptions: {
    expirationDate: string | null;
    validFromDate: string | null;
    title: string;
    description: string;
    isSearchEnabled: boolean;
  },
  quizOptions: IQuizOptionsFormData,
  gamification: GamificationInfoDto,
  lang: string | undefined
}>('/courses/saveCourseOptions', 
  async ({ courseOptions, quizOptions, gamification, lang }, thunkAPI) => {
    try {
      const { course } = (thunkAPI.getState() as RootState).courses;
      if (!course || !course.id) throw new Error();

      const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
      if (!coursesClientApi) throw new Error();

      const globalCourseSettingsBody: GlobalCourseSettingsDto = {
        ...courseOptions,
        ...quizOptions,
        gamification,
      };

      await coursesClientApi.editCourseByLang(course.id, lang || course.documentLanguage || '', globalCourseSettingsBody);

      return {
        courseOptions,
        quizOptions,
        gamification
      };
    } catch (err) {
      console.error(err);
      throw err;
    }
  }, {
    getPendingMeta: () => ({
      apiStatus: ApiStatus.SAVING
    })
  }
);

export const saveLanguageCollection = createAsyncThunk<SupportedLanguageDto[], SupportedLanguageDto[]>('/courses/saveLanguageCollection', 
  async (languageCollection, thunkAPI) => {
    try {
      const { course } = (thunkAPI.getState() as RootState).courses;
      if (!course || !course.id) throw new Error();

      const { coursesFunctionClientApi } = (thunkAPI.getState() as RootState).clients.clients;
      if (!coursesFunctionClientApi) throw new Error();

      const body: TranslateRequestDto = {
        courseId: course?.id || '',
        supportedLanguages: languageCollection,
      };
      await coursesFunctionClientApi.translateCourse(course?.id || '', body);
      return languageCollection;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }, {
    getPendingMeta: () => ({
      apiStatus: ApiStatus.SAVING
    })
  }
);

export const addSchedule = createAsyncThunk<ScheduleDto[], ScheduleDto>('/courses/addSchedule', async (schedule, thunkAPI) => {
  try {
    const { course } = (thunkAPI.getState() as RootState).courses;
    if (!course || !course.id || !course.schedules) throw new Error();

    const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesClientApi) throw new Error();

    const response = await coursesClientApi.addSchedule(course.id, schedule);
    const newSchedule = response.data;
    const newSchedules = [...course.schedules];
    newSchedules.push(newSchedule);
    return newSchedules;
  } catch (err) {
    console.error(err);
    throw err;
  }
}, {
  getPendingMeta: () => ({
    apiStatus: ApiStatus.SAVING
  })
});

export const editSchedule = createAsyncThunk<ScheduleDto[], ScheduleDto>('/courses/editSchedule', async (schedule, thunkAPI) => {
  try {
    const { course } = (thunkAPI.getState() as RootState).courses;
    if (!course || !course.id || !course.schedules) throw new Error();

    const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesClientApi) throw new Error();

    const schedules = [...course.schedules];
    const userSchedule = schedules.find(el => el.recipient.id === schedule.recipient.id && !el.archived);
    if (!userSchedule || !userSchedule.id) throw new Error();

    schedule.id = userSchedule.id;
    schedule.archived = userSchedule.archived;
    schedule.executions = userSchedule.executions;
    schedule.lessonsSent = userSchedule.lessonsSent;
    if (schedule.recipient.profilePicture === null ||
        schedule.recipient.profilePicture === undefined
    ) schedule.recipient = {...userSchedule.recipient, profilePicture: ''};

    await coursesClientApi.editSchedule(course.id, userSchedule.id, schedule);
    const newSchedules = [...course.schedules];
    const currentScheduleIndex = newSchedules.findIndex(el => el.id === userSchedule.id);
    if (currentScheduleIndex === -1) throw new Error();
    newSchedules[currentScheduleIndex] = schedule;
    return newSchedules;
  } catch (err) {
    console.error(err);
    throw err;
  }
}, {
  getPendingMeta: () => ({
    apiStatus: ApiStatus.SAVING
  })
});

export const deleteSchedule = createAsyncThunk<ScheduleDto[], ScheduleDto>('/courses/deleteSchedule', async (schedule, thunkAPI) => {
  try {
    const { course } = (thunkAPI.getState() as RootState).courses;
    if (!course || !course.id || !course.schedules || !schedule.id) throw new Error();

    const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesClientApi) throw new Error();

    await coursesClientApi.archiveSchedule(course.id, schedule.id);
    const newSchedules = [...course.schedules];
    const deleteScheduleIndex = newSchedules.findIndex(el => ((el.id === schedule.id) && !el.archived));
    newSchedules[deleteScheduleIndex] = { ...newSchedules[deleteScheduleIndex], archived: true };
    return newSchedules;
  } catch (err) {
    console.error(err);
    throw err;
  }
}, {
  getPendingMeta: () => ({
    apiStatus: ApiStatus.SAVING
  })
});

export const sendSchedule = createAsyncThunk<ScheduleDto[], ScheduleDto>('/courses/sendSchedule', async (schedule, thunkAPI) => {
  try {
    const { course } = (thunkAPI.getState() as RootState).courses;
    if (!course || !course.id || !course.schedules || !schedule.id) throw new Error();

    const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesClientApi) throw new Error();

    await coursesClientApi.sendSchedule(course.id, schedule.id);
    const newSchedules = [...course.schedules];
    const sendScheduleIndex = newSchedules.findIndex(el => ((el.id === schedule.id) && !el.archived));
    newSchedules[sendScheduleIndex] = { ...newSchedules[sendScheduleIndex], status: ScheduleStatusDto.Running };
    return newSchedules;
  } catch (err) {
    console.error(err);
    throw err;
  }
}, {
  getPendingMeta: () => ({
    apiStatus: ApiStatus.SAVING
  })
});

export const saveLesson = 
  createAsyncThunk<(BulletPointLessonDto | FinalQuizLessonDto | QuizLessonDto | TextLessonDto)[], { 
    lesson: Partial<TextLessonDto> | Partial<BulletPointLessonDto> | Partial<QuizLessonDto>, lang: string | undefined}>('/saveLesson', 
    async ({ lesson, lang }, thunkAPI) => {

  try {
    if (!lesson.id) throw new Error();

    const { course } = (thunkAPI.getState() as RootState).courses;
    if (!course || !course.id || !course.lessons) throw new Error();

    const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesClientApi) throw new Error();

    await coursesClientApi.editLesson(course.id, lesson.id, lang || course.documentLanguage || '', lesson);

    lesson.modified = DateTime.now().toISO();
    const newLessons = [...course.lessons];
    const lessonIndex = newLessons.findIndex(el => el.id === lesson.id);
    if (lessonIndex === -1) throw new Error();
    newLessons[lessonIndex] = lesson;
    return newLessons;
  } catch (err) {
    console.error(err);
    throw err;
  }
}, {
  getPendingMeta: () => ({
    apiStatus: ApiStatus.SAVING
  })
});

export const editAttachment = 
  createAsyncThunk<void, { 
    lessonId: string, lang: string, attachment: IAttachment | undefined | null}>('/courses/editAttachment', 
    async ({ lessonId, lang, attachment }, thunkAPI) => {

      console.log('attachment', attachment);

  try {
    if (!lessonId) throw new Error();

    const { course } = (thunkAPI.getState() as RootState).courses;
    if (!course || !course.id || !course.lessons) throw new Error();

    const { token } = (thunkAPI.getState() as RootState).account;
    if (!token) throw new Error();

    const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesClientApi) throw new Error();
  
    if (attachment?.data && typeof attachment?.data === 'string')
      await coursesClientApi.editLessonAttachment(course.id, lessonId, lang, attachment?.data ? {data: attachment?.data || '', contentType: attachment?.contentType || ''} : undefined);
    else {
      const formData = new FormData();
      formData.append('attachment', attachment?.data as File);
      console.log('attachment', attachment);
      await axios.post(`${ApplicationSettings.IAI_APP_API_URL}/api/Courses/${course.id}/${lang}/lessons/${lessonId}/rawUploadAttachment`, {
        attachment: attachment?.data,
      }, { headers: {
        'Authorization': `Bearer ${token}`, // Replace with your actual authorization token
        'Content-Type': 'multipart/form-data',
      }},)
    }

  } catch (err) {
    console.error(err);
    throw err;
  }
}, {
  getPendingMeta: () => ({
    apiStatus: ApiStatus.SAVING
  })
});

export const deleteAttachment = 
  createAsyncThunk<void, { 
    lessonId: string, lang: string}>('/courses/deleteAttachment', 
    async ({ lessonId, lang }, thunkAPI) => {

  try {
    if (!lessonId) throw new Error();

    const { course } = (thunkAPI.getState() as RootState).courses;
    if (!course || !course.id || !course.lessons) throw new Error();

    const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesClientApi) throw new Error();

    await coursesClientApi.deleteLessonAttachment(course.id, lessonId, lang);
  } catch (err) {
    console.error(err);
    throw err;
  }
}, {
  getPendingMeta: () => ({
    apiStatus: ApiStatus.SAVING
  })
});

export const addLesson = 
  createAsyncThunk<(BulletPointLessonDto | FinalQuizLessonDto | QuizLessonDto | TextLessonDto)[], { 
    lesson: Partial<TextLessonDto> | Partial<BulletPointLessonDto> | Partial<QuizLessonDto>, lang: string | undefined}>('/courses/addLesson', 
    async ({ lesson, lang }, thunkAPI) => {

  try {
    const { course } = (thunkAPI.getState() as RootState).courses;
    if (!course || !course.id || !course.lessons) throw new Error();

    const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesClientApi) throw new Error();

    const response = await coursesClientApi.addLesson(course.id, lang || course.documentLanguage || '', lesson);
    const newLessonId = (response.data as unknown) as string;
    if (!newLessonId) throw new Error();
    lesson.id = newLessonId;
    lesson.modified = DateTime.now().toISO();

    const newLessons = [...course.lessons];
    newLessons.unshift(lesson);
    return newLessons;
  } catch (err) {
    console.error(err);
    throw err;
  }
}, {
  getPendingMeta: () => ({
    apiStatus: ApiStatus.SAVING
  })
});

export const changeLessonType = 
  createAsyncThunk<void, { lessonId: string | undefined; type: LessonTypeDto | undefined }>('/courses/changeLessonType', 
    async ({ lessonId, type }, thunkAPI) => {

  try {
    if (!lessonId || !type) return;

    const { course } = (thunkAPI.getState() as RootState).courses;
    if (!course || !course.id) throw new Error();

    const { coursesFunctionClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesFunctionClientApi) throw new Error();

    await coursesFunctionClientApi.changeLessonType(course.id, lessonId, { 
      courseId: course.id,
      lessonId,
      type
     });

  } catch (err) {
    console.error(err);
    throw err;
  }
}, {
  getPendingMeta: () => ({
    apiStatus: ApiStatus.SAVING
  })
});

export const generateLesson = 
  createAsyncThunk<string, GenerateLessonRequestDto>('/courses/generateLesson', 
    async (request, thunkAPI) => {

  try {
    const { course } = (thunkAPI.getState() as RootState).courses;
    if (!course || !course.id) throw new Error();

    const { coursesFunctionClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesFunctionClientApi) throw new Error();

    const response = await coursesFunctionClientApi.createLesson(course.id, request);
    const newLessonId = response.data;
    return newLessonId;
  } catch (err) {
    console.error(err);
    throw err;
  }
}, {
  getPendingMeta: () => ({
    apiStatus: ApiStatus.SAVING,
  })
});

export const generateFinalQuiz = 
  createAsyncThunk<void, void>('/courses/generateFinalQuiz', 
    async (_, thunkAPI) => {

  try {
    const { course } = (thunkAPI.getState() as RootState).courses;
    if (!course || !course.id) throw new Error();

    const { coursesFunctionClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesFunctionClientApi) throw new Error();

    await coursesFunctionClientApi.generateFinalQuiz(course.id);
  } catch (err) {
    console.error(err);
    throw err;
  }
}, {
  getPendingMeta: () => ({
    apiStatus: ApiStatus.SAVING
  })
});

export const cloneCourse = 
  createAsyncThunk<void, void>('/courses/cloneCourse', 
    async (_, thunkAPI) => {

  try {
    const { course } = (thunkAPI.getState() as RootState).courses;
    if (!course || !course.id) throw new Error();

    const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesClientApi) throw new Error();

    await coursesClientApi.clone(course.id);
  } catch (err) {
    console.error(err);
    throw err;
  }
}, {
  getPendingMeta: () => ({
    apiStatus: ApiStatus.SAVING
  })
});

export const editFinalQuiz = 
  createAsyncThunk<FinalQuizLessonDto, { finalQuiz: FinalQuizLessonDto, lang: string | undefined }>('/courses/editFinalQuiz', 
    async ({ finalQuiz, lang }, thunkAPI) => {

  try {
    const { course } = (thunkAPI.getState() as RootState).courses;
    if (!course || !course.id || !course.lessons) throw new Error();

    const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesClientApi) throw new Error();

    if (finalQuiz.title === null || finalQuiz.quote === null) {
      finalQuiz.title = '';
      finalQuiz.quote = '';
    }

    await coursesClientApi.editFinalQuiz(course.id, lang || course.documentLanguage || '', finalQuiz);

    return finalQuiz;
  } catch (err) {
    console.error(err);
    throw err;
  }
}, {
  getPendingMeta: () => ({
    apiStatus: ApiStatus.SAVING
  })
});


export const clearError = createAsyncThunk<CourseStatusDto, void>('/courses/clearError', async (_, thunkAPI) => {
  try {
    const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    const { course } = (thunkAPI.getState() as RootState).courses;
    if (!coursesClientApi || !course || !course.id) throw new Error();
   
    const response = await coursesClientApi.clearCourseError(course.id);
    return (response.data as unknown) as CourseStatusDto;
  } catch (err) {
    console.error(err);
    throw err;
  }
}, {
  getPendingMeta: () => ({
    apiStatus: ApiStatus.SAVING
  })
});

export const clearJustGenerated = createAsyncThunk<void, void>('/courses/clearJustGenerated', async (_, thunkAPI) => {
  try {
    const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    const { course } = (thunkAPI.getState() as RootState).courses;
    if (!coursesClientApi || !course || !course.id) throw new Error();
   
    await coursesClientApi.setCourseStatusInfo(course.id, { justGenerated: false });
  } catch (err) {
    console.error(err);
    throw err;
  }
}, {
  getPendingMeta: () => ({
    apiStatus: ApiStatus.SAVING
  })
});

const initialState: ICoursesState = {
  courses: undefined,
  course: undefined,
  lessonToEdit: undefined,
  lessonToDelete: undefined,
  // apiStatus: ApiStatus.IDLE,
  apiStatus: {
    status: ApiStatus.IDLE,
    errorMessage: '',
  }
};

export const coursesSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    setCourse: (state, action: {type: string; payload: string | undefined}) => (
      {
        ...state,
        course: state.courses?.find(el => el.id === action.payload),
      }
    ),
    cleanCourses: (state, action: {}) => (
      {
        ...state,
        courses: undefined,
      }
    ),
    cleanCourse: (state, action: {}) => ({
      ...state,
      course: undefined,
      courses: state.courses?.map(course => course.id === state.course?.id ? { ...state.course } : course),
    }),
    setLessonToEdit: (state, action: {type: string; payload: LessonDto | undefined}) => (
      {
        ...state,
        lessonToEdit: action.payload,
      }
    ),
    setLessonsOrder: (state, action: {type: string; payload: LessonDto[]}) => {
      return (
      {
        ...state,
        course: {
          ...state.course,
          lessons: state.course?.lessons?.find(lesson => lesson.type === LessonTypeDto.FinalQuiz) ?
           [...action.payload, ...state.course.lessons?.filter(lesson => lesson.type === LessonTypeDto.FinalQuiz)] : 
           [...action.payload],
        }
      }
    )},
    cleanLessonToEdit: (state, action: { type: string, payload: void }) => (
      {
        ...state,
        lessonToEdit: undefined,
      }
    ),
    setLessonToDelete: (state, action: {type: string; payload: LessonDto | undefined}) => (
      {
        ...state,
        lessonToDelete: action.payload,
      }
    ),
    cleanLessonToDelete: (state, action: { type: string, payload: void }) => (
      {
        ...state,
        lessonToDelete: undefined,
      }
    ),
    updateGenerationProgress: (state, action: { type: string, payload: { id: string, progress: number } }) => (
      {
        ...state,
        courses: 
          state.courses?.map(course => {
            if (course.id === action.payload.id) {
              return {
                ...course,
                generationProgress: action.payload.progress,
              }
            }
            return course;
          }),
      }
    ),
    cancelError: (state, action: { type: string, payload: void }) => (
      {
        ...state,
        apiStatus: {
          status: ApiStatus.IDLE,
          errorMessage: '',
        },
      }
    ),
    setIDLE: (state, action: { type: string, payload: void }) => (
      {
        ...state,
        apiStatus: {
          status: ApiStatus.IDLE,
          errorMessage: '',
        },
      }
    ),
    setStatus: (state, action: { type: string, payload: { status: CourseStatusDto } }) => (
      {
        ...state,
        course: {
          ...state.course,
          status: action.payload.status,
        }
      }
    )
  },
  extraReducers: (builder) => {
    // generateCourse
    // builder.addCase(generateCourse.pending, (state, action) => {
    //   return {
    //     ...state,
    //     apiStatus: {
    //       status: ApiStatus.SAVING,
    //       errorMessage: '',
    //     },
    //   }
    // });
    builder.addCase(generateCourse.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.SUCCESS,
          errorMessage: '',
        },
      }
    });
    builder.addCase(generateCourse.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.ERROR,
          errorMessage: action.payload as string,
        },
      }
    });

    // create/from-scratch
    // builder.addCase(createFromScratch.pending, (state, action) => {
    //   return {
    //     ...state,
    //     apiStatus: {
    //       status: ApiStatus.SAVING,
    //       errorMessage: '',
    //     },
    //   }
    // });
    builder.addCase(createFromScratch.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.SUCCESS,
          errorMessage: '',
        },
      }
    });
    builder.addCase(createFromScratch.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.ERROR,
          errorMessage: action.payload as string,
        },
      }
    });

    // getCourses
    // builder.addCase(getCourses.pending, (state, action) => {
    //   return {
    //     ...state,
    //     apiStatus: {
    //       status: ApiStatus.LOADING,
    //       errorMessage: '',
    //     },
    //   }
    // });
    builder.addCase(getCourses.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.SUCCESS,
          errorMessage: '',
        },
        courses: action.payload,
        course: undefined,
      }
    });
    builder.addCase(getCourses.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.ERROR,
          errorMessage: '',
        },
      }
    });

    // // getProfilePhotos
    // builder.addCase(getProfilePhotos.fulfilled, (state, action) => {
    //   return {
    //     ...state,
    //     apiStatus: {
    //       status: ApiStatus.SUCCESS,
    //       errorMessage: '',
    //     },
    //     courses: action.payload
    //   }
    // });

    // getCourse
    // builder.addCase(getCourse.pending, (state, action) => {
    //   return {
    //     ...state,
    //     apiStatus: {
    //       status: ApiStatus.LOADING,
    //       errorMessage: '',
    //     },
    //   }
    // });
    builder.addCase(getCourse.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.SUCCESS,
          errorMessage: '',
        },
        course: action.payload
      }
    });
    builder.addCase(getCourse.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.ERROR,
          errorMessage: '',
        },
      }
    });

    // getCourseAfterGeneration
    builder.addCase(getCourseAfterGeneration.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.SUCCESS,
          errorMessage: '',
        },
        courses: state.courses?.map((el: ICourse) => el.id === action.payload.id ? {
          ...action.payload,
          createdBy: el.createdBy,
        } : el),
      }
    });
    builder.addCase(getCourseAfterGeneration.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.ERROR,
          errorMessage: '',
        },
      }
    });

    // changeOrder
    // builder.addCase(changeLessonOrder.pending, (state, action) => {
    //   return {
    //     ...state,
    //     apiStatus: {
    //       status: ApiStatus.SAVING,
    //       errorMessage: '',
    //     },
    //   }
    // });
    builder.addCase(changeLessonOrder.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.SUCCESS,
          errorMessage: '',
        },
      }
    });

    // // toggleHide
    // builder.addCase(toggleHide.pending, (state, action) => {
    //   return {
    //     ...state,
    //     apiStatus: {
    //       ...state.apiStatus,
    //       courseStatus: {
    //         ...state.apiStatus.courseStatus,
    //         api: ApiStatus.LOADING,
    //         loadingMessage: action.meta.arg.lesson.isHide ? 'Unhiding lessons...' : 'Hiding lesson...',
    //       },
    //     },
    //   }
    // });
    // builder.addCase(toggleHide.fulfilled, (state, action) => {
    //   return {
    //     ...state,
    //     apiStatus: {
    //       ...state.apiStatus,
    //       courseStatus: {
    //         ...state.apiStatus.courseStatus,
    //         api: ApiStatus.SUCCESS,
    //         loadingMessage: '',
    //       },
    //     },
    //     course: {
    //       ...state.course,
    //       lessons: state.course?.lessons?.map(el => el.id === action.payload?.id ? {...action.payload} : el),
    //     }
    //   }
    // });
    // builder.addCase(toggleHide.rejected, (state, action) => {
    //   return {
    //     ...state,
    //     apiStatus: {
    //       ...state.apiStatus,
    //       courseStatus: {
    //         ...state.apiStatus.courseStatus,
    //         api: ApiStatus.ERROR,
    //         loadingMessage: '',
    //       },
    //     },
    //   }
    // });

    // deleteLesson
    // builder.addCase(deleteLesson.pending, (state, action) => {
    //   return {
    //     ...state,
    //     apiStatus: {
    //       status: ApiStatus.SAVING,
    //       errorMessage: '',
    //     },
    //   }
    // });
    builder.addCase(deleteLesson.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.SUCCESS,
          errorMessage: '',
        },
        course: {
          ...state.course,
          lessons: action.payload,
        }
      }
    });
    builder.addCase(deleteLesson.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.ERROR,
          errorMessage: '',
        },
      }
    });

    // publishCourse
    // builder.addCase(publishCourse.pending, (state, action) => {
    //   return {
    //     ...state,
    //     apiStatus: {
    //       status: ApiStatus.SAVING_AND_WAIT,
    //       errorMessage: '',
    //     },
    //   }
    // });
    builder.addCase(publishCourse.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.SUCCESS,
          errorMessage: '',
        },
        course: {
          ...state.course,
          status: CourseStatusDto.Published,
        }
      }
    });
    builder.addCase(publishCourse.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.ERROR,
          errorMessage: '',
        },
      }
    });

    // unpublishCourse
    // builder.addCase(unpublishCourse.pending, (state, action) => {
    //   return {
    //     ...state,
    //     apiStatus: {
    //       status: ApiStatus.SAVING_AND_WAIT,
    //       errorMessage: '',
    //     },
    //   }
    // });
    builder.addCase(unpublishCourse.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.SUCCESS,
          errorMessage: '',
        },
        course: {
          ...state.course,
          status: CourseStatusDto.Draft,
        }
      }
    });
    builder.addCase(unpublishCourse.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.ERROR,
          errorMessage: '',
        },
      }
    });

    // deleteCourse
    // builder.addCase(deleteCourse.pending, (state, action) => {
    //   return {
    //     ...state,
    //     apiStatus: {
    //       status: ApiStatus.SAVING_AND_WAIT,
    //       errorMessage: '',
    //     },
    //   }
    // });
    builder.addCase(deleteCourse.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.SUCCESS,
          errorMessage: '',
        },
        courses: state.courses?.filter(course => course.id !== state.course?.id),
        course: undefined
      }
    });
    builder.addCase(deleteCourse.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.ERROR,
          errorMessage: '',
        },
      }
    });

    // deleteCourseInError
    // builder.addCase(deleteCourseInError.pending, (state, action) => {
    //   return {
    //     ...state,
    //     apiStatus: {
    //       status: ApiStatus.LOADING,
    //       errorMessage: '',
    //     },
    //   }
    // });
    builder.addCase(deleteCourseInError.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.SUCCESS,
          errorMessage: '',
        },
        courses: action.payload,
      }
    });
    builder.addCase(deleteCourseInError.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.ERROR,
          errorMessage: '',
        },
      }
    });

    // saveCourseOptions
    // builder.addCase(saveCourseOptions.pending, (state, action) => {
    //   return {
    //     ...state,
    //     apiStatus: {
    //       status: ApiStatus.SAVING,
    //       errorMessage: '',
    //     },
    //   }
    // });
    builder.addCase(saveCourseOptions.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.SUCCESS,
          errorMessage: '',
        },
        course: {
          ...state.course,
          ...action.payload.courseOptions,
          ...action.payload.quizOptions,
          gamification: {...action.payload.gamification}
        }
      }
    });
    builder.addCase(saveCourseOptions.rejected, (state) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.ERROR,
          errorMessage: '',
        },
      }
    });

    // saveLanguageCollection
    // builder.addCase(saveLanguageCollection.pending, (state, action) => {
    //   return {
    //     ...state,
    //     apiStatus: {
    //       status: ApiStatus.SAVING,
    //       errorMessage: '',
    //     },
    //   }
    // });
    builder.addCase(saveLanguageCollection.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.SUCCESS,
          errorMessage: '',
        },
        course: {
          ...state.course,
          supportedLanguages: action.payload,
        }
      }
    });
    builder.addCase(saveLanguageCollection.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.ERROR,
          errorMessage: '',
        },
      }
    });

    // addSchedule
    // builder.addCase(addSchedule.pending, (state, action) => {
    //   return {
    //     ...state,
    //     apiStatus: {
    //       status: ApiStatus.SAVING,
    //       errorMessage: '',
    //     },
    //   }
    // });
    builder.addCase(addSchedule.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.SUCCESS,
          errorMessage: '',
        },
        course: {
          ...state.course,
          schedules: action.payload,
        }
      }
    });
    builder.addCase(addSchedule.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.ERROR,
          errorMessage: '',
        },
      }
    });

    // editSchedule
    // builder.addCase(editSchedule.pending, (state, action) => {
    //   return {
    //     ...state,
    //     apiStatus: {
    //       status: ApiStatus.SAVING,
    //       errorMessage: '',
    //     },
    //   }
    // });
    builder.addCase(editSchedule.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.SUCCESS,
          errorMessage: '',
        },
        course: {
          ...state.course,
          schedules: action.payload,
        }
      }
    });
    builder.addCase(editSchedule.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.ERROR,
          errorMessage: '',
        },
      }
    });

    // deleteSchedule
    // builder.addCase(deleteSchedule.pending, (state, action) => {
    //   return {
    //     ...state,
    //     apiStatus: {
    //       status: ApiStatus.SAVING,
    //       errorMessage: '',
    //     },
    //   }
    // });
    builder.addCase(deleteSchedule.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.SUCCESS,
          errorMessage: '',
        },
        course: {
          ...state.course,
          schedules: action.payload,
        }
      }
    });
    builder.addCase(deleteSchedule.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.ERROR,
          errorMessage: '',
        },
      }
    });

    // sendSchedule
    // builder.addCase(sendSchedule.pending, (state, action) => {
    //   return {
    //     ...state,
    //     apiStatus: {
    //       status: ApiStatus.SAVING,
    //       errorMessage: '',
    //     },
    //   }
    // });
    builder.addCase(sendSchedule.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.SUCCESS,
          errorMessage: '',
        },
        course: {
          ...state.course,
          schedules: action.payload,
        }
      }
    });
    builder.addCase(sendSchedule.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.ERROR,
          errorMessage: '',
        },
      }
    });

    // saveLesson
    // builder.addCase(saveLesson.pending, (state, action) => {
    //   return {
    //     ...state,
    //     apiStatus: {
    //       status: ApiStatus.SAVING,
    //       errorMessage: '',
    //     },
    //   }
    // });
    builder.addCase(saveLesson.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.SUCCESS,
          errorMessage: '',
        },
        course: {
          ...state.course,
          lessons: action.payload,
        }
      }
    });
    builder.addCase(saveLesson.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.ERROR,
          errorMessage: '',
        },
      }
    });

    // editAttachment
    // builder.addCase(editAttachment.pending, (state, action) => {
    //   return {
    //     ...state,
    //     apiStatus: {
    //       status: ApiStatus.SAVING,
    //       errorMessage: '',
    //     },
    //   }
    // });
    builder.addCase(editAttachment.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.SUCCESS,
          errorMessage: '',
        },
      }
    });
    builder.addCase(editAttachment.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.ERROR,
          errorMessage: '',
        },
      }
    });

    // deleteAttachment
    // builder.addCase(deleteAttachment.pending, (state, action) => {
    //   return {
    //     ...state,
    //     apiStatus: {
    //       status: ApiStatus.SAVING,
    //       errorMessage: '',
    //     },
    //   }
    // });
    builder.addCase(deleteAttachment.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.SUCCESS,
          errorMessage: '',
        },
      }
    });
    builder.addCase(deleteAttachment.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.ERROR,
          errorMessage: '',
        },
      }
    });

    // addLesson
    // builder.addCase(addLesson.pending, (state, action) => {
    //   return {
    //     ...state,
    //     apiStatus: {
    //       status: ApiStatus.SAVING,
    //       errorMessage: '',
    //     },
    //   }
    // });
    builder.addCase(addLesson.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.SUCCESS,
          errorMessage: '',
        },
        course: {
          ...state.course,
          lessons: action.payload,
        }
      }
    });
    builder.addCase(addLesson.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.ERROR,
          errorMessage: '',
        },
      }
    });

    // generateLesson
    // builder.addCase(generateLesson.pending, (state, action) => {
    //   return {
    //     ...state,
    //     apiStatus: {
    //       status: ApiStatus.SAVING_AND_WAIT,
    //       errorMessage: '',
    //     },
    //   }
    // });
    builder.addCase(generateLesson.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.SUCCESS,
          errorMessage: '',
        },
      }
    });
    builder.addCase(generateLesson.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.ERROR,
          errorMessage: '',
        },
      }
    });

    // generateFinalQuiz
    // builder.addCase(generateFinalQuiz.pending, (state, action) => {
    //   return {
    //     ...state,
    //     apiStatus: {
    //       status: ApiStatus.SAVING_AND_WAIT,
    //       errorMessage: '',
    //     },
    //   }
    // });
    builder.addCase(generateFinalQuiz.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.SUCCESS,
          errorMessage: '',
        },
      }
    });
    builder.addCase(generateFinalQuiz.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.ERROR,
          errorMessage: '',
        },
      }
    });

    // cloneCourse
    // builder.addCase(cloneCourse.pending, (state, action) => {
    //   return {
    //     ...state,
    //     apiStatus: {
    //       status: ApiStatus.SAVING_AND_WAIT,
    //       errorMessage: '',
    //     },
    //   }
    // });
    builder.addCase(cloneCourse.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.SUCCESS,
          errorMessage: '',
        },
      }
    });
    builder.addCase(cloneCourse.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.ERROR,
          errorMessage: '',
        },
      }
    });

    // editFinalQuiz
    // builder.addCase(editFinalQuiz.pending, (state, action) => {
    //   return {
    //     ...state,
    //     apiStatus: {
    //       status: ApiStatus.SAVING,
    //       errorMessage: '',
    //     },
    //   }
    // });
    builder.addCase(editFinalQuiz.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.SUCCESS,
          errorMessage: '',
        },
        course: {
          ...state.course,
          lessons: state.course?.lessons?.map((lesson) => {
            if (lesson.id === action.payload.id) {
              return action.payload;
            }
            return lesson;
          })
        }
      }
    });
    builder.addCase(editFinalQuiz.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.ERROR,
          errorMessage: '',
        },
      }
    });

    // clearJustGenerated
    builder.addCase(clearJustGenerated.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.SUCCESS,
          errorMessage: '',
        },
        course: {
          ...state.course,
          statusInfo: {
            justGenerated: false,
          },
        }
      }
    });
    builder.addCase(clearJustGenerated.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          status: ApiStatus.ERROR,
          errorMessage: '',
        },
      }
    });

    builder.addMatcher(
      (action): action is ReturnType<AsyncThunk<any, any, { pendingMeta: { apiStatus: ApiStatus } }>['pending']> => 
        action.type.endsWith('/pending'),
      (state, action) => {
        state.apiStatus = {
          status: action.meta.apiStatus || ApiStatus.LOADING,
          errorMessage: '',
        };
      }
    );
  }
});

export default coursesSlice.reducer;
export const {
  setCourse,
  cleanCourses,
  cleanCourse,
  setLessonToEdit,
  setLessonsOrder,
  cleanLessonToEdit,
  setLessonToDelete,
  cleanLessonToDelete,
  updateGenerationProgress,
  cancelError,
  setIDLE,
  setStatus,
} = coursesSlice.actions;