import { IDropdownOption } from "@fluentui/react";
import { AddLessonSettingsDto, FileTypeDto, GenerateLessonRequestDto, LessonTypeDto } from "api-client";
import MLForm from "common/ml-components/MLForm";
import MLModal from "common/ml-components/MLModal";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import { getClassNames } from "./GenerateLesson.classNames";
import MLDropdown, { DropdownStyles } from "common/ml-components/inputs/MLDropdown";
import { lessonTypesOptions } from 'utils/constants/lesson-types';
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { generateLesson } from "store/slices/courses";
import MLTextField from "common/ml-components/inputs/MLTextField";
import { LoadingContext } from "contexts/loading-context";
import { timeout } from "utils/helpers/helpers";
import { useNavigate } from "react-router-dom";

interface IGenerateLessonProps {
  show: boolean;
  onDismiss: () => void;
}

interface IGenerateLessonFormData {
  lessonType: LessonTypeDto;
  pages: string;
  chapterIndex: string | undefined;
}

const GenerateLesson: React.FC<IGenerateLessonProps> = ({ show, onDismiss }) => {
  const coursesClientApi = useSelector((state: RootState) => state.clients.clients.coursesClientApi);
  const courseId = useSelector((state: RootState) => state.courses.course?.id);
  const documentLanguage = useSelector((state: RootState) => state.courses.course?.documentLanguage);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { startLoading, stopLoading } = useContext(LoadingContext);

  const defaultValues: IGenerateLessonFormData = {
    lessonType: (lessonTypesOptions[0].key as LessonTypeDto),
    pages: '',
    chapterIndex: undefined,
  };

  const [addLessonSettings, setAddLessonSettings] = useState<AddLessonSettingsDto>();

  const getAddLessonSettings = useCallback(async (): Promise<void> => {
    if (!coursesClientApi) return;

    if(!courseId || !documentLanguage) return;

    try {
      const response = await coursesClientApi.getAddLessonSettings(courseId);
      setAddLessonSettings(response.data);
    } catch(err){
      console.error(err);
    }
  }, [courseId, documentLanguage, coursesClientApi]);

  const chapters: IDropdownOption[] = useMemo(() => 
    (addLessonSettings?.chapters && 
      addLessonSettings.chapters.map(e => ({ key: e, text: e }))) || [], [addLessonSettings]);

  const onSubmit = async (data: IGenerateLessonFormData) => {
    if (!coursesClientApi || !courseId) return;

    try {
      const body: GenerateLessonRequestDto = {
        lessonType: data.lessonType,
        chapterIndex: data.chapterIndex ? addLessonSettings?.chapters?.indexOf(data.chapterIndex) : 0,
        pages: data.pages,
      }
      await dispatch(generateLesson(body)).unwrap();
      startLoading('Redirecting to the learnings page...');
      await timeout(6000);
      // await dispatch(getCourses()).unwrap();
      stopLoading();
      navigate('/learnings');
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getAddLessonSettings();
  }, [getAddLessonSettings]);

  const classes = getClassNames();

  return (
    <MLModal
      show={show}
      width="40rem"
    >
      <MLForm
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        onDismiss={onDismiss}
        submitButtonLabel="Generate"
        title="Generate lesson"
      >
        <div className={classes.generateLessonContainer}>
          <MLDropdown
            name='lessonType'
            label='Lesson type'
            formRequired
            options={lessonTypesOptions}
            dropdownStyle={DropdownStyles.LIGHT}
            dropdownWidth={200}
            // leftLabel
          />

          {addLessonSettings?.fileType === FileTypeDto.Pdf && (
            <>
              <MLTextField
                name="pages"
                label="Pages"
                formRequired
              />
            </>
          )}

          {addLessonSettings?.fileType === FileTypeDto.Docx && (
            <>
              <MLDropdown
                name="chapterIndex"
                label="Chapter"
                options={chapters}
                dropdownStyle={DropdownStyles.LIGHT}
                formRequired
                // leftLabel
              />
            </>
          )}
        </div>
      </MLForm>
    </MLModal>
  );
};

export default GenerateLesson;