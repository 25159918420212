/* tslint:disable */
/* eslint-disable */
/**
 * MicroLearning
 * MicroLearning (ASP.NET Core 6.0)
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: dev@intranet.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * @type AddLessonRequest
 * @export
 */
export type AddLessonRequest = AudibleLessonDto | AudioOnlyLessonDto | BulletPointLessonDto | CompleteTheSentenceLessonDto | FinalQuizLessonDto | FlashCardLessonDto | QuizLessonDto | TextLessonDto | TrueFalseLessonDto;

/**
 * 
 * @export
 * @interface AddLessonSettingsDto
 */
export interface AddLessonSettingsDto {
    /**
     * 
     * @type {boolean}
     * @memberof AddLessonSettingsDto
     */
    'showChapters'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AddLessonSettingsDto
     */
    'showError'?: boolean;
    /**
     * 
     * @type {FileType}
     * @memberof AddLessonSettingsDto
     */
    'fileType'?: FileType;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddLessonSettingsDto
     */
    'chapters'?: Array<string>;
}


/**
 * 
 * @export
 * @interface AddWhitelistedDomainDto
 */
export interface AddWhitelistedDomainDto {
    /**
     * 
     * @type {string}
     * @memberof AddWhitelistedDomainDto
     */
    'domain'?: string;
}
/**
 * 
 * @export
 * @interface AdvancedExportScormSettingsDto
 */
export interface AdvancedExportScormSettingsDto {
    /**
     * 
     * @type {boolean}
     * @memberof AdvancedExportScormSettingsDto
     */
    'includeAudio'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdvancedExportScormSettingsDto
     */
    'excludeVideos'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdvancedExportScormSettingsDto
     */
    'specifiedLanguages'?: Array<string> | null;
}
/**
 * @type ApiTenantSearchValueGet200ResponseInner
 * @export
 */
export type ApiTenantSearchValueGet200ResponseInner = GroupDto | UserDto;

/**
 * 
 * @export
 * @interface AudibleLessonDto
 */
export interface AudibleLessonDto {
    /**
     * 
     * @type {string}
     * @memberof AudibleLessonDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AudibleLessonDto
     */
    'title'?: string;
    /**
     * 
     * @type {LessonTypeDto}
     * @memberof AudibleLessonDto
     */
    'type'?: LessonTypeDto;
    /**
     * 
     * @type {boolean}
     * @memberof AudibleLessonDto
     */
    'isHide'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AudibleLessonDto
     */
    'quote'?: string;
    /**
     * 
     * @type {string}
     * @memberof AudibleLessonDto
     */
    'modified'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AudibleLessonDto
     */
    'scratch'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof AudibleLessonDto
     */
    'expectedDuration'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AudibleLessonDto
     */
    'showAudioPlayer'?: boolean;
}


/**
 * 
 * @export
 * @interface AudioOnlyLessonDto
 */
export interface AudioOnlyLessonDto {
    /**
     * 
     * @type {string}
     * @memberof AudioOnlyLessonDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AudioOnlyLessonDto
     */
    'title'?: string;
    /**
     * 
     * @type {LessonTypeDto}
     * @memberof AudioOnlyLessonDto
     */
    'type'?: LessonTypeDto;
    /**
     * 
     * @type {boolean}
     * @memberof AudioOnlyLessonDto
     */
    'isHide'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AudioOnlyLessonDto
     */
    'quote'?: string;
    /**
     * 
     * @type {string}
     * @memberof AudioOnlyLessonDto
     */
    'modified'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AudioOnlyLessonDto
     */
    'scratch'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof AudioOnlyLessonDto
     */
    'expectedDuration'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AudioOnlyLessonDto
     */
    'showAudioPlayer'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AudioOnlyLessonDto
     */
    'audioSource'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AudioOnlyLessonDto
     */
    'isUrl'?: boolean;
}


/**
 * 
 * @export
 * @interface BadgeAchievementInfo
 */
export interface BadgeAchievementInfo {
    /**
     * 
     * @type {string}
     * @memberof BadgeAchievementInfo
     */
    'acquisitionDateTime'?: string;
    /**
     * 
     * @type {GamificationBadge}
     * @memberof BadgeAchievementInfo
     */
    'badgeInfo'?: GamificationBadge;
}
/**
 * 
 * @export
 * @interface BaseEventValueDto
 */
export interface BaseEventValueDto {
    /**
     * 
     * @type {string}
     * @memberof BaseEventValueDto
     */
    'lessonId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseEventValueDto
     */
    'language'?: string;
    /**
     * 
     * @type {EventValueTypeDto}
     * @memberof BaseEventValueDto
     */
    'type'?: EventValueTypeDto;
}


/**
 * 
 * @export
 * @interface BulletPointLessonDto
 */
export interface BulletPointLessonDto {
    /**
     * 
     * @type {string}
     * @memberof BulletPointLessonDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BulletPointLessonDto
     */
    'title'?: string;
    /**
     * 
     * @type {LessonTypeDto}
     * @memberof BulletPointLessonDto
     */
    'type'?: LessonTypeDto;
    /**
     * 
     * @type {boolean}
     * @memberof BulletPointLessonDto
     */
    'isHide'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BulletPointLessonDto
     */
    'quote'?: string;
    /**
     * 
     * @type {string}
     * @memberof BulletPointLessonDto
     */
    'modified'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BulletPointLessonDto
     */
    'scratch'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof BulletPointLessonDto
     */
    'expectedDuration'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof BulletPointLessonDto
     */
    'showAudioPlayer'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BulletPointLessonDto
     */
    'introduction'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BulletPointLessonDto
     */
    'bulletPoints'?: Array<string>;
}


/**
 * 
 * @export
 * @interface ChangeLessonTypeRequestDto
 */
export interface ChangeLessonTypeRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ChangeLessonTypeRequestDto
     */
    'courseId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeLessonTypeRequestDto
     */
    'lessonId'?: string;
    /**
     * 
     * @type {LessonTypeDto}
     * @memberof ChangeLessonTypeRequestDto
     */
    'type'?: LessonTypeDto;
}


/**
 * 
 * @export
 * @interface CheckLmsDto
 */
export interface CheckLmsDto {
    /**
     * 
     * @type {string}
     * @memberof CheckLmsDto
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckLmsDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckLmsDto
     */
    'courseId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckLmsDto
     */
    'domain'?: string;
}
/**
 * 
 * @export
 * @interface CompleteTheSentenceLessonDto
 */
export interface CompleteTheSentenceLessonDto {
    /**
     * 
     * @type {string}
     * @memberof CompleteTheSentenceLessonDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompleteTheSentenceLessonDto
     */
    'title'?: string;
    /**
     * 
     * @type {LessonTypeDto}
     * @memberof CompleteTheSentenceLessonDto
     */
    'type'?: LessonTypeDto;
    /**
     * 
     * @type {boolean}
     * @memberof CompleteTheSentenceLessonDto
     */
    'isHide'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompleteTheSentenceLessonDto
     */
    'quote'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompleteTheSentenceLessonDto
     */
    'modified'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompleteTheSentenceLessonDto
     */
    'scratch'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof CompleteTheSentenceLessonDto
     */
    'expectedDuration'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompleteTheSentenceLessonDto
     */
    'showAudioPlayer'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompleteTheSentenceLessonDto
     */
    'sentence'?: string;
    /**
     * 
     * @type {number}
     * @memberof CompleteTheSentenceLessonDto
     */
    'correctWordId'?: number;
    /**
     * 
     * @type {Array<IndexedResponseDto>}
     * @memberof CompleteTheSentenceLessonDto
     */
    'options'?: Array<IndexedResponseDto>;
}


/**
 * 
 * @export
 * @interface CompletedEventValueDto
 */
export interface CompletedEventValueDto {
    /**
     * 
     * @type {string}
     * @memberof CompletedEventValueDto
     */
    'lessonId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompletedEventValueDto
     */
    'language'?: string;
    /**
     * 
     * @type {EventValueTypeDto}
     * @memberof CompletedEventValueDto
     */
    'type'?: EventValueTypeDto;
    /**
     * 
     * @type {string}
     * @memberof CompletedEventValueDto
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompletedEventValueDto
     */
    'endDate'?: string;
}


/**
 * 
 * @export
 * @interface CourseCategoryDto
 */
export interface CourseCategoryDto {
    /**
     * 
     * @type {string}
     * @memberof CourseCategoryDto
     */
    'id'?: string;
    /**
     * 
     * @type {Array<TranslationDto>}
     * @memberof CourseCategoryDto
     */
    'categoryTranslations'?: Array<TranslationDto>;
    /**
     * 
     * @type {boolean}
     * @memberof CourseCategoryDto
     */
    'archived'?: boolean;
}
/**
 * 
 * @export
 * @interface CourseContentDto
 */
export interface CourseContentDto {
    /**
     * 
     * @type {string}
     * @memberof CourseContentDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseContentDto
     */
    'language'?: string;
    /**
     * 
     * @type {Array<AddLessonRequest>}
     * @memberof CourseContentDto
     */
    'lessons'?: Array<AddLessonRequest>;
}
/**
 * 
 * @export
 * @interface CourseProgressDto
 */
export interface CourseProgressDto {
    /**
     * 
     * @type {string}
     * @memberof CourseProgressDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseProgressDto
     */
    'courseId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseProgressDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CourseProgressDto
     */
    'latestUpdate'?: string;
    /**
     * 
     * @type {CourseProgressUserInfoDto}
     * @memberof CourseProgressDto
     */
    'userInfo'?: CourseProgressUserInfoDto;
    /**
     * 
     * @type {GamificationUserStatsInfoDto}
     * @memberof CourseProgressDto
     */
    'gamificationStats'?: GamificationUserStatsInfoDto;
    /**
     * 
     * @type {Array<LessonProgressDto>}
     * @memberof CourseProgressDto
     */
    'lessonProgresses'?: Array<LessonProgressDto>;
    /**
     * 
     * @type {boolean}
     * @memberof CourseProgressDto
     */
    'finalQuizPassed'?: boolean;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof CourseProgressDto
     */
    'courseCompletedInLang'?: { [key: string]: boolean; };
    /**
     * 
     * @type {string}
     * @memberof CourseProgressDto
     */
    'courseCompletedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CourseProgressDto
     */
    'finalQuizPassedDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CourseProgressDto
     */
    'finalQuizAttempts'?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseProgressDto
     */
    'finalQuizLatestScore'?: number;
}
/**
 * 
 * @export
 * @interface CourseProgressUserInfoDto
 */
export interface CourseProgressUserInfoDto {
    /**
     * 
     * @type {string}
     * @memberof CourseProgressUserInfoDto
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseProgressUserInfoDto
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseProgressUserInfoDto
     */
    'upn'?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseProgressUserInfoDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseProgressUserInfoDto
     */
    'userTenantId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CourseProgressUserInfoDto
     */
    'hasSchedule'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CourseStatusDto = {
    Generating: 'Generating',
    Draft: 'Draft',
    Published: 'Published',
    Running: 'Running',
    Cancelled: 'Cancelled',
    GenError: 'GenError'
} as const;

export type CourseStatusDto = typeof CourseStatusDto[keyof typeof CourseStatusDto];


/**
 * 
 * @export
 * @interface CourseStatusInfoDto
 */
export interface CourseStatusInfoDto {
    /**
     * 
     * @type {boolean}
     * @memberof CourseStatusInfoDto
     */
    'justGenerated'?: boolean;
}
/**
 * 
 * @export
 * @interface CourseV2Dto
 */
export interface CourseV2Dto {
    /**
     * 
     * @type {string}
     * @memberof CourseV2Dto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseV2Dto
     */
    'categoryId'?: string;
    /**
     * 
     * @type {CourseStatusDto}
     * @memberof CourseV2Dto
     */
    'status'?: CourseStatusDto;
    /**
     * 
     * @type {FileType}
     * @memberof CourseV2Dto
     */
    'generatedByFileType'?: FileType;
    /**
     * 
     * @type {Array<TranslationDto>}
     * @memberof CourseV2Dto
     */
    'titleTranslations'?: Array<TranslationDto> | null;
    /**
     * 
     * @type {Array<TranslationDto>}
     * @memberof CourseV2Dto
     */
    'descriptionTranslations'?: Array<TranslationDto> | null;
    /**
     * 
     * @type {Array<CourseContentDto>}
     * @memberof CourseV2Dto
     */
    'courseContents'?: Array<CourseContentDto> | null;
    /**
     * 
     * @type {Array<SupportedLanguageDto>}
     * @memberof CourseV2Dto
     */
    'supportedLanguages'?: Array<SupportedLanguageDto>;
    /**
     * 
     * @type {Array<ScheduleDto>}
     * @memberof CourseV2Dto
     */
    'schedules'?: Array<ScheduleDto>;
    /**
     * 
     * @type {string}
     * @memberof CourseV2Dto
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseV2Dto
     */
    'modified'?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseV2Dto
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseV2Dto
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseV2Dto
     */
    'documentLanguage'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CourseV2Dto
     */
    'archived'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseV2Dto
     */
    'generatedByLetAI'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseV2Dto
     */
    'isFinalQuizRepeatable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CourseV2Dto
     */
    'passingPercentage'?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseV2Dto
     */
    'expirationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CourseV2Dto
     */
    'validFromDate'?: string | null;
    /**
     * 
     * @type {Array<MLErrorDto>}
     * @memberof CourseV2Dto
     */
    'errors'?: Array<MLErrorDto>;
    /**
     * 
     * @type {string}
     * @memberof CourseV2Dto
     */
    'chapterHeaderPrefix'?: string;
    /**
     * 
     * @type {CourseStatusInfoDto}
     * @memberof CourseV2Dto
     */
    'statusInfo'?: CourseStatusInfoDto | null;
    /**
     * 
     * @type {boolean}
     * @memberof CourseV2Dto
     */
    'isSearchEnabled'?: boolean;
    /**
     * 
     * @type {GamificationInfoDto}
     * @memberof CourseV2Dto
     */
    'gamification'?: GamificationInfoDto | null;
    /**
     * 
     * @type {VisibilitySettingsDto}
     * @memberof CourseV2Dto
     */
    'visibilitySettings'?: VisibilitySettingsDto | null;
    /**
     * 
     * @type {CourseCategoryDto}
     * @memberof CourseV2Dto
     */
    'category'?: CourseCategoryDto | null;
    /**
     * 
     * @type {number}
     * @memberof CourseV2Dto
     */
    'generationProgress'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CourseV2Dto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseV2Dto
     */
    'description'?: string;
    /**
     * 
     * @type {Array<AddLessonRequest>}
     * @memberof CourseV2Dto
     */
    'lessons'?: Array<AddLessonRequest>;
}


/**
 * 
 * @export
 * @interface EnterEventValueDto
 */
export interface EnterEventValueDto {
    /**
     * 
     * @type {string}
     * @memberof EnterEventValueDto
     */
    'lessonId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnterEventValueDto
     */
    'language'?: string;
    /**
     * 
     * @type {EventValueTypeDto}
     * @memberof EnterEventValueDto
     */
    'type'?: EventValueTypeDto;
    /**
     * 
     * @type {string}
     * @memberof EnterEventValueDto
     */
    'source'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EnterEventValueDto
     */
    'isAssigned'?: boolean;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ErrorLevelDto = {
    Critical: 'Critical',
    Error: 'Error',
    Warning: 'Warning'
} as const;

export type ErrorLevelDto = typeof ErrorLevelDto[keyof typeof ErrorLevelDto];


/**
 * 
 * @export
 * @interface EventDto
 */
export interface EventDto {
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    'timestamp'?: string;
    /**
     * 
     * @type {EventTypeDto}
     * @memberof EventDto
     */
    'eventType'?: EventTypeDto;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    'courseId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    'userPrincipalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    'userEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    'targetId'?: string;
    /**
     * 
     * @type {EventDtoEventValue}
     * @memberof EventDto
     */
    'eventValue'?: EventDtoEventValue;
}


/**
 * @type EventDtoEventValue
 * @export
 */
export type EventDtoEventValue = BaseEventValueDto | CompletedEventValueDto | EnterEventValueDto | FinalQuizCompletedEventValueDto | LeaveEventValueDto | QuizEventValueDto;

/**
 * 
 * @export
 * @enum {string}
 */

export const EventTypeDto = {
    ClickComplete: 'ClickComplete',
    SendNotify: 'SendNotify',
    ClickNotify: 'ClickNotify',
    ClickQuizOption: 'ClickQuizOption',
    ClickAudio: 'ClickAudio',
    LeavePage: 'LeavePage',
    EnterPage: 'EnterPage',
    FinalQuizCompleted: 'FinalQuizCompleted',
    EnterLesson: 'EnterLesson',
    LeaveLesson: 'LeaveLesson'
} as const;

export type EventTypeDto = typeof EventTypeDto[keyof typeof EventTypeDto];


/**
 * 
 * @export
 * @enum {string}
 */

export const EventValueTypeDto = {
    Base: 'Base',
    Completed: 'Completed',
    QuizOption: 'QuizOption',
    Leave: 'Leave',
    Enter: 'Enter',
    FinalQuizCompleted: 'FinalQuizCompleted'
} as const;

export type EventValueTypeDto = typeof EventValueTypeDto[keyof typeof EventValueTypeDto];


/**
 * 
 * @export
 * @interface FileConfigurationDto
 */
export interface FileConfigurationDto {
    /**
     * 
     * @type {FileTypeDto}
     * @memberof FileConfigurationDto
     */
    'fileType'?: FileTypeDto;
    /**
     * 
     * @type {string}
     * @memberof FileConfigurationDto
     */
    'pages'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileConfigurationDto
     */
    'chapterHeaderPrefix'?: string;
}


/**
 * 
 * @export
 * @interface FileDto
 */
export interface FileDto {
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'data'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'contentType'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const FileType = {
    None: 'None',
    Docx: 'Docx',
    Pdf: 'Pdf',
    Pptx: 'Pptx'
} as const;

export type FileType = typeof FileType[keyof typeof FileType];


/**
 * 
 * @export
 * @enum {string}
 */

export const FileTypeDto = {
    None: 'None',
    Docx: 'Docx',
    Pdf: 'Pdf',
    Pptx: 'Pptx'
} as const;

export type FileTypeDto = typeof FileTypeDto[keyof typeof FileTypeDto];


/**
 * 
 * @export
 * @interface FinalQuizAnswersDto
 */
export interface FinalQuizAnswersDto {
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof FinalQuizAnswersDto
     */
    'answers'?: { [key: string]: number; };
}
/**
 * 
 * @export
 * @interface FinalQuizCompletedEventValueDto
 */
export interface FinalQuizCompletedEventValueDto {
    /**
     * 
     * @type {string}
     * @memberof FinalQuizCompletedEventValueDto
     */
    'lessonId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FinalQuizCompletedEventValueDto
     */
    'language'?: string;
    /**
     * 
     * @type {EventValueTypeDto}
     * @memberof FinalQuizCompletedEventValueDto
     */
    'type'?: EventValueTypeDto;
    /**
     * 
     * @type {number}
     * @memberof FinalQuizCompletedEventValueDto
     */
    'score'?: number;
    /**
     * 
     * @type {number}
     * @memberof FinalQuizCompletedEventValueDto
     */
    'requiredScore'?: number;
    /**
     * 
     * @type {string}
     * @memberof FinalQuizCompletedEventValueDto
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof FinalQuizCompletedEventValueDto
     */
    'endDate'?: string;
}


/**
 * 
 * @export
 * @interface FinalQuizLessonDto
 */
export interface FinalQuizLessonDto {
    /**
     * 
     * @type {string}
     * @memberof FinalQuizLessonDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FinalQuizLessonDto
     */
    'title'?: string;
    /**
     * 
     * @type {LessonTypeDto}
     * @memberof FinalQuizLessonDto
     */
    'type'?: LessonTypeDto;
    /**
     * 
     * @type {boolean}
     * @memberof FinalQuizLessonDto
     */
    'isHide'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FinalQuizLessonDto
     */
    'quote'?: string;
    /**
     * 
     * @type {string}
     * @memberof FinalQuizLessonDto
     */
    'modified'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FinalQuizLessonDto
     */
    'scratch'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FinalQuizLessonDto
     */
    'expectedDuration'?: number | null;
    /**
     * 
     * @type {Array<SafeQuizLessonDto>}
     * @memberof FinalQuizLessonDto
     */
    'quizes'?: Array<SafeQuizLessonDto>;
}


/**
 * 
 * @export
 * @interface FlashCardLessonDto
 */
export interface FlashCardLessonDto {
    /**
     * 
     * @type {string}
     * @memberof FlashCardLessonDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlashCardLessonDto
     */
    'title'?: string;
    /**
     * 
     * @type {LessonTypeDto}
     * @memberof FlashCardLessonDto
     */
    'type'?: LessonTypeDto;
    /**
     * 
     * @type {boolean}
     * @memberof FlashCardLessonDto
     */
    'isHide'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FlashCardLessonDto
     */
    'quote'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlashCardLessonDto
     */
    'modified'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FlashCardLessonDto
     */
    'scratch'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FlashCardLessonDto
     */
    'expectedDuration'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FlashCardLessonDto
     */
    'question'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlashCardLessonDto
     */
    'answer'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlashCardLessonDto
     */
    'explanation'?: string;
}


/**
 * 
 * @export
 * @interface GamificationBadge
 */
export interface GamificationBadge {
    /**
     * 
     * @type {string}
     * @memberof GamificationBadge
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GamificationBadge
     */
    'badgeTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof GamificationBadge
     */
    'imageUrl'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GamificationBadge
     */
    'achievementPoints'?: number;
    /**
     * 
     * @type {number}
     * @memberof GamificationBadge
     */
    'sharePointListItemId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof GamificationBadge
     */
    'archived'?: boolean;
}
/**
 * 
 * @export
 * @interface GamificationBadgeDto
 */
export interface GamificationBadgeDto {
    /**
     * 
     * @type {string}
     * @memberof GamificationBadgeDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GamificationBadgeDto
     */
    'badgeTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof GamificationBadgeDto
     */
    'imageUrl'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GamificationBadgeDto
     */
    'achievementPoints'?: number;
    /**
     * 
     * @type {number}
     * @memberof GamificationBadgeDto
     */
    'sharePointListItemId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof GamificationBadgeDto
     */
    'archived'?: boolean | null;
}
/**
 * 
 * @export
 * @interface GamificationInfoDto
 */
export interface GamificationInfoDto {
    /**
     * 
     * @type {number}
     * @memberof GamificationInfoDto
     */
    'courseCompletitionBonusPoints'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GamificationInfoDto
     */
    'oneShotExamPassingBonusPoints'?: number | null;
    /**
     * 
     * @type {GamificationPointDto}
     * @memberof GamificationInfoDto
     */
    'courseCompletitionPoints'?: GamificationPointDto | null;
}
/**
 * 
 * @export
 * @interface GamificationPointDto
 */
export interface GamificationPointDto {
    /**
     * 
     * @type {number}
     * @memberof GamificationPointDto
     */
    'totalMaxPoints'?: number;
    /**
     * 
     * @type {number}
     * @memberof GamificationPointDto
     */
    'minimumPoints'?: number;
    /**
     * 
     * @type {string}
     * @memberof GamificationPointDto
     */
    'targetCompletitionDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof GamificationPointDto
     */
    'degradationPoints'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GamificationPointDto
     */
    'degradationPeriod'?: string | null;
}
/**
 * 
 * @export
 * @interface GamificationUserStatsInfoDto
 */
export interface GamificationUserStatsInfoDto {
    /**
     * 
     * @type {string}
     * @memberof GamificationUserStatsInfoDto
     */
    'gamificationScoringHash'?: string;
    /**
     * 
     * @type {number}
     * @memberof GamificationUserStatsInfoDto
     */
    'scoredPoints'?: number;
}
/**
 * 
 * @export
 * @interface GenerateConfigurationDto
 */
export interface GenerateConfigurationDto {
    /**
     * 
     * @type {string}
     * @memberof GenerateConfigurationDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateConfigurationDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateConfigurationDto
     */
    'categoryId'?: string;
    /**
     * 
     * @type {number}
     * @memberof GenerateConfigurationDto
     */
    'lessonsNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof GenerateConfigurationDto
     */
    'documentLanguage'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GenerateConfigurationDto
     */
    'hasTextLesson'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GenerateConfigurationDto
     */
    'hasBulletPointsLesson'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GenerateConfigurationDto
     */
    'hasQuizLesson'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GenerateConfigurationDto
     */
    'hasFlashCardLesson'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GenerateConfigurationDto
     */
    'hasAudioOnlyLesson'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GenerateConfigurationDto
     */
    'hasTrueFalseLesson'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GenerateConfigurationDto
     */
    'hasCompleteTheSentenceLesson'?: boolean;
}
/**
 * 
 * @export
 * @interface GenerateLessonRequestDto
 */
export interface GenerateLessonRequestDto {
    /**
     * 
     * @type {string}
     * @memberof GenerateLessonRequestDto
     */
    'courseId'?: string;
    /**
     * 
     * @type {LessonTypeDto}
     * @memberof GenerateLessonRequestDto
     */
    'lessonType'?: LessonTypeDto;
    /**
     * 
     * @type {number}
     * @memberof GenerateLessonRequestDto
     */
    'chapterIndex'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GenerateLessonRequestDto
     */
    'pages'?: string | null;
}


/**
 * 
 * @export
 * @interface GenerateRequestDto
 */
export interface GenerateRequestDto {
    /**
     * 
     * @type {string}
     * @memberof GenerateRequestDto
     */
    'courseId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GenerateRequestDto
     */
    'data'?: string | null;
    /**
     * 
     * @type {GenerateConfigurationDto}
     * @memberof GenerateRequestDto
     */
    'configuration'?: GenerateConfigurationDto | null;
    /**
     * 
     * @type {FileConfigurationDto}
     * @memberof GenerateRequestDto
     */
    'fileConfiguration'?: FileConfigurationDto | null;
    /**
     * 
     * @type {boolean}
     * @memberof GenerateRequestDto
     */
    'isRecovery'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const GenerationActionCode = {
    FirstCourseCreation: 'FirstCourseCreation',
    Translation: 'Translation',
    AddNewLesson: 'AddNewLesson',
    EditLesson: 'EditLesson',
    FinalQuizGeneration: 'FinalQuizGeneration'
} as const;

export type GenerationActionCode = typeof GenerationActionCode[keyof typeof GenerationActionCode];


/**
 * 
 * @export
 * @interface GlobalCourseSettingsDto
 */
export interface GlobalCourseSettingsDto {
    /**
     * 
     * @type {string}
     * @memberof GlobalCourseSettingsDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalCourseSettingsDto
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GlobalCourseSettingsDto
     */
    'isFinalQuizRepeatable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GlobalCourseSettingsDto
     */
    'passingPercentage'?: number;
    /**
     * 
     * @type {string}
     * @memberof GlobalCourseSettingsDto
     */
    'expirationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalCourseSettingsDto
     */
    'validFromDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GlobalCourseSettingsDto
     */
    'isSearchEnabled'?: boolean;
    /**
     * 
     * @type {GamificationInfoDto}
     * @memberof GlobalCourseSettingsDto
     */
    'gamification'?: GamificationInfoDto;
}
/**
 * 
 * @export
 * @interface GroupDto
 */
export interface GroupDto {
    /**
     * 
     * @type {string}
     * @memberof GroupDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupDto
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupDto
     */
    'profilePicture'?: string | null;
    /**
     * 
     * @type {IdentityTypeDto}
     * @memberof GroupDto
     */
    'type'?: IdentityTypeDto;
}


/**
 * 
 * @export
 * @interface IdentityDto
 */
export interface IdentityDto {
    /**
     * 
     * @type {string}
     * @memberof IdentityDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityDto
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityDto
     */
    'profilePicture'?: string | null;
    /**
     * 
     * @type {IdentityTypeDto}
     * @memberof IdentityDto
     */
    'type'?: IdentityTypeDto;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const IdentityTypeDto = {
    User: 'User',
    Group: 'Group'
} as const;

export type IdentityTypeDto = typeof IdentityTypeDto[keyof typeof IdentityTypeDto];


/**
 * 
 * @export
 * @interface IndexedResponseDto
 */
export interface IndexedResponseDto {
    /**
     * 
     * @type {number}
     * @memberof IndexedResponseDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof IndexedResponseDto
     */
    'response'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const LanguageStatusDto = {
    Draft: 'Draft',
    Published: 'Published'
} as const;

export type LanguageStatusDto = typeof LanguageStatusDto[keyof typeof LanguageStatusDto];


/**
 * 
 * @export
 * @interface LearningContentDto
 */
export interface LearningContentDto {
    /**
     * 
     * @type {string}
     * @memberof LearningContentDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LearningContentDto
     */
    'externalId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof LearningContentDto
     */
    'additionalTags'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof LearningContentDto
     */
    'contentWebUrl'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof LearningContentDto
     */
    'contributors'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof LearningContentDto
     */
    'createdDateTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LearningContentDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LearningContentDto
     */
    'duration'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LearningContentDto
     */
    'format'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LearningContentDto
     */
    'isActive'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof LearningContentDto
     */
    'isPremium'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof LearningContentDto
     */
    'isSearchable'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof LearningContentDto
     */
    'languageTag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LearningContentDto
     */
    'lastModifiedDateTime'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LearningContentDto
     */
    'numberOfPages'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof LearningContentDto
     */
    'skillTags'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof LearningContentDto
     */
    'sourceName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LearningContentDto
     */
    'thumbnailWebUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LearningContentDto
     */
    'title'?: string | null;
}
/**
 * 
 * @export
 * @interface LearningProviderDto
 */
export interface LearningProviderDto {
    /**
     * 
     * @type {string}
     * @memberof LearningProviderDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LearningProviderDto
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LearningProviderDto
     */
    'loginWebUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LearningProviderDto
     */
    'longLogoWebUrlForDarkTheme'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LearningProviderDto
     */
    'longLogoWebUrlForLightTheme'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LearningProviderDto
     */
    'squareLogoWebUrlForDarkTheme'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LearningProviderDto
     */
    'squareLogoWebUrlForLightTheme'?: string | null;
    /**
     * 
     * @type {Array<LearningContentDto>}
     * @memberof LearningProviderDto
     */
    'learningContents'?: Array<LearningContentDto> | null;
}
/**
 * 
 * @export
 * @interface LeaveEventValueDto
 */
export interface LeaveEventValueDto {
    /**
     * 
     * @type {string}
     * @memberof LeaveEventValueDto
     */
    'lessonId'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeaveEventValueDto
     */
    'language'?: string;
    /**
     * 
     * @type {EventValueTypeDto}
     * @memberof LeaveEventValueDto
     */
    'type'?: EventValueTypeDto;
    /**
     * 
     * @type {number}
     * @memberof LeaveEventValueDto
     */
    'timeSpentMs'?: number;
}


/**
 * 
 * @export
 * @interface LessonDto
 */
export interface LessonDto {
    /**
     * 
     * @type {string}
     * @memberof LessonDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof LessonDto
     */
    'title'?: string;
    /**
     * 
     * @type {LessonTypeDto}
     * @memberof LessonDto
     */
    'type'?: LessonTypeDto;
    /**
     * 
     * @type {boolean}
     * @memberof LessonDto
     */
    'isHide'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LessonDto
     */
    'quote'?: string;
    /**
     * 
     * @type {string}
     * @memberof LessonDto
     */
    'modified'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LessonDto
     */
    'scratch'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof LessonDto
     */
    'expectedDuration'?: number | null;
}


/**
 * 
 * @export
 * @interface LessonProgressDto
 */
export interface LessonProgressDto {
    /**
     * 
     * @type {string}
     * @memberof LessonProgressDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof LessonProgressDto
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof LessonProgressDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LessonProgressDto
     */
    'isFinalQuizAttempt'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LessonProgressDto
     */
    'score'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LessonProgressDto
     */
    'requiredScore'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LessonProgressDto
     */
    'completedLanguage'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const LessonTypeDto = {
    Text: 'Text',
    BulletPoints: 'BulletPoints',
    Quiz: 'Quiz',
    AudioOnly: 'AudioOnly',
    FinalQuiz: 'FinalQuiz',
    FlashCard: 'FlashCard'
} as const;

export type LessonTypeDto = typeof LessonTypeDto[keyof typeof LessonTypeDto];


/**
 * 
 * @export
 * @interface LightCourseProgressDto
 */
export interface LightCourseProgressDto {
    /**
     * 
     * @type {CourseStatusDto}
     * @memberof LightCourseProgressDto
     */
    'status'?: CourseStatusDto;
    /**
     * 
     * @type {GenerationActionCode}
     * @memberof LightCourseProgressDto
     */
    'latestAction'?: GenerationActionCode | null;
    /**
     * 
     * @type {number}
     * @memberof LightCourseProgressDto
     */
    'progressPercentage'?: number | null;
}


/**
 * 
 * @export
 * @interface LmsStatusInfoDto
 */
export interface LmsStatusInfoDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof LmsStatusInfoDto
     */
    'whitelistedDomains'?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof LmsStatusInfoDto
     */
    'coursesParticipants'?: { [key: string]: number; };
}
/**
 * 
 * @export
 * @interface MLErrorDto
 */
export interface MLErrorDto {
    /**
     * 
     * @type {ErrorLevelDto}
     * @memberof MLErrorDto
     */
    'level'?: ErrorLevelDto;
    /**
     * 
     * @type {number}
     * @memberof MLErrorDto
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof MLErrorDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof MLErrorDto
     */
    'created'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MLErrorDto
     */
    'managed'?: boolean | null;
}


/**
 * 
 * @export
 * @interface QuizEventValueDto
 */
export interface QuizEventValueDto {
    /**
     * 
     * @type {string}
     * @memberof QuizEventValueDto
     */
    'lessonId'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuizEventValueDto
     */
    'language'?: string;
    /**
     * 
     * @type {EventValueTypeDto}
     * @memberof QuizEventValueDto
     */
    'type'?: EventValueTypeDto;
    /**
     * 
     * @type {string}
     * @memberof QuizEventValueDto
     */
    'quizOption'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof QuizEventValueDto
     */
    'isCorrectOption'?: boolean;
}


/**
 * 
 * @export
 * @interface QuizLessonDto
 */
export interface QuizLessonDto {
    /**
     * 
     * @type {string}
     * @memberof QuizLessonDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuizLessonDto
     */
    'title'?: string;
    /**
     * 
     * @type {LessonTypeDto}
     * @memberof QuizLessonDto
     */
    'type'?: LessonTypeDto;
    /**
     * 
     * @type {boolean}
     * @memberof QuizLessonDto
     */
    'isHide'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof QuizLessonDto
     */
    'quote'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuizLessonDto
     */
    'modified'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof QuizLessonDto
     */
    'scratch'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof QuizLessonDto
     */
    'expectedDuration'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof QuizLessonDto
     */
    'question'?: string;
    /**
     * 
     * @type {Array<IndexedResponseDto>}
     * @memberof QuizLessonDto
     */
    'options'?: Array<IndexedResponseDto>;
    /**
     * 
     * @type {number}
     * @memberof QuizLessonDto
     */
    'correctAnswerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof QuizLessonDto
     */
    'explanationCorrectAnswer'?: string;
}


/**
 * 
 * @export
 * @interface SafeQuizLessonDto
 */
export interface SafeQuizLessonDto {
    /**
     * 
     * @type {string}
     * @memberof SafeQuizLessonDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SafeQuizLessonDto
     */
    'question'?: string;
    /**
     * 
     * @type {Array<IndexedResponseDto>}
     * @memberof SafeQuizLessonDto
     */
    'options'?: Array<IndexedResponseDto>;
    /**
     * 
     * @type {number}
     * @memberof SafeQuizLessonDto
     */
    'correctAnswerId'?: number;
}
/**
 * 
 * @export
 * @interface ScheduleDto
 */
export interface ScheduleDto {
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduleDto
     */
    'archived'?: boolean;
    /**
     * 
     * @type {ApiTenantSearchValueGet200ResponseInner}
     * @memberof ScheduleDto
     */
    'recipient': ApiTenantSearchValueGet200ResponseInner;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    'timeZoneId': string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    'nextExecution'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScheduleDto
     */
    'lessonsSent'?: Array<string>;
    /**
     * 
     * @type {ScheduleStatusDto}
     * @memberof ScheduleDto
     */
    'status': ScheduleStatusDto;
    /**
     * 
     * @type {number}
     * @memberof ScheduleDto
     */
    'frequency': number;
    /**
     * 
     * @type {number}
     * @memberof ScheduleDto
     */
    'executions'?: number;
    /**
     * 
     * @type {Array<ScheduleUserInfoDto>}
     * @memberof ScheduleDto
     */
    'targetUsersInfo'?: Array<ScheduleUserInfoDto>;
    /**
     * 
     * @type {ScheduleMethodDto}
     * @memberof ScheduleDto
     */
    'method'?: ScheduleMethodDto;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDto
     */
    'usedTenantId'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ScheduleMethodDto = {
    Default: 'Default',
    Teams: 'Teams',
    Email: 'Email'
} as const;

export type ScheduleMethodDto = typeof ScheduleMethodDto[keyof typeof ScheduleMethodDto];


/**
 * 
 * @export
 * @enum {string}
 */

export const ScheduleStatusDto = {
    Draft: 'Draft',
    Running: 'Running',
    Completed: 'Completed'
} as const;

export type ScheduleStatusDto = typeof ScheduleStatusDto[keyof typeof ScheduleStatusDto];


/**
 * 
 * @export
 * @interface ScheduleUserInfoDto
 */
export interface ScheduleUserInfoDto {
    /**
     * 
     * @type {string}
     * @memberof ScheduleUserInfoDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleUserInfoDto
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleUserInfoDto
     */
    'upn'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleUserInfoDto
     */
    'email'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ScormStandard = {
    V11: 'v1_1',
    V12: 'v1_2',
    V20041ed: 'v2004_1ed',
    V20042ed: 'v2004_2ed',
    V20043ed: 'v2004_3ed',
    V20044ed: 'v2004_4ed'
} as const;

export type ScormStandard = typeof ScormStandard[keyof typeof ScormStandard];


/**
 * 
 * @export
 * @interface ScratchCourseDto
 */
export interface ScratchCourseDto {
    /**
     * 
     * @type {string}
     * @memberof ScratchCourseDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScratchCourseDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScratchCourseDto
     */
    'categoryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScratchCourseDto
     */
    'documentLanguage'?: string;
}
/**
 * 
 * @export
 * @interface SearchCitation
 */
export interface SearchCitation {
    /**
     * 
     * @type {string}
     * @memberof SearchCitation
     */
    'citationsPlaceholder'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchCitation
     */
    'content'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchCitation
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchCitation
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface SearchRequestDto
 */
export interface SearchRequestDto {
    /**
     * 
     * @type {string}
     * @memberof SearchRequestDto
     */
    'prompt'?: string;
}
/**
 * 
 * @export
 * @interface SearchResult
 */
export interface SearchResult {
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchResult
     */
    'intents'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SearchResult
     */
    'response'?: string;
    /**
     * 
     * @type {Array<SearchCitation>}
     * @memberof SearchResult
     */
    'citations'?: Array<SearchCitation>;
}
/**
 * 
 * @export
 * @interface SettingsDto
 */
export interface SettingsDto {
    /**
     * 
     * @type {string}
     * @memberof SettingsDto
     */
    'id'?: string;
    /**
     * 
     * @type {Array<CourseCategoryDto>}
     * @memberof SettingsDto
     */
    'categories'?: Array<CourseCategoryDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SettingsDto
     */
    'supportedLanguages'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SettingsDto
     */
    'learningProviderId'?: string | null;
}
/**
 * 
 * @export
 * @interface SupportedLanguageDto
 */
export interface SupportedLanguageDto {
    /**
     * 
     * @type {string}
     * @memberof SupportedLanguageDto
     */
    'language'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SupportedLanguageDto
     */
    'isPublishable'?: boolean;
    /**
     * 
     * @type {LanguageStatusDto}
     * @memberof SupportedLanguageDto
     */
    'status'?: LanguageStatusDto;
    /**
     * 
     * @type {string}
     * @memberof SupportedLanguageDto
     */
    'publishedUrl'?: string | null;
}


/**
 * 
 * @export
 * @interface TextLessonDto
 */
export interface TextLessonDto {
    /**
     * 
     * @type {string}
     * @memberof TextLessonDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextLessonDto
     */
    'title'?: string;
    /**
     * 
     * @type {LessonTypeDto}
     * @memberof TextLessonDto
     */
    'type'?: LessonTypeDto;
    /**
     * 
     * @type {boolean}
     * @memberof TextLessonDto
     */
    'isHide'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TextLessonDto
     */
    'quote'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextLessonDto
     */
    'modified'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TextLessonDto
     */
    'scratch'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TextLessonDto
     */
    'expectedDuration'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TextLessonDto
     */
    'showAudioPlayer'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TextLessonDto
     */
    'body'?: string;
}


/**
 * 
 * @export
 * @interface TimeZoneDto
 */
export interface TimeZoneDto {
    /**
     * 
     * @type {string}
     * @memberof TimeZoneDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeZoneDto
     */
    'windowsId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeZoneDto
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeZoneDto
     */
    'standardName'?: string;
}
/**
 * 
 * @export
 * @interface TranslateRequestDto
 */
export interface TranslateRequestDto {
    /**
     * 
     * @type {string}
     * @memberof TranslateRequestDto
     */
    'courseId'?: string;
    /**
     * 
     * @type {Array<SupportedLanguageDto>}
     * @memberof TranslateRequestDto
     */
    'supportedLanguages'?: Array<SupportedLanguageDto>;
}
/**
 * 
 * @export
 * @interface TranslationDto
 */
export interface TranslationDto {
    /**
     * 
     * @type {string}
     * @memberof TranslationDto
     */
    'language'?: string;
    /**
     * 
     * @type {string}
     * @memberof TranslationDto
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface TrueFalseLessonDto
 */
export interface TrueFalseLessonDto {
    /**
     * 
     * @type {string}
     * @memberof TrueFalseLessonDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrueFalseLessonDto
     */
    'title'?: string;
    /**
     * 
     * @type {LessonTypeDto}
     * @memberof TrueFalseLessonDto
     */
    'type'?: LessonTypeDto;
    /**
     * 
     * @type {boolean}
     * @memberof TrueFalseLessonDto
     */
    'isHide'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TrueFalseLessonDto
     */
    'quote'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrueFalseLessonDto
     */
    'modified'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TrueFalseLessonDto
     */
    'scratch'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TrueFalseLessonDto
     */
    'expectedDuration'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TrueFalseLessonDto
     */
    'showAudioPlayer'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TrueFalseLessonDto
     */
    'sentence'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TrueFalseLessonDto
     */
    'isTrue'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TrueFalseLessonDto
     */
    'explanation'?: string;
}


/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'profilePicture'?: string | null;
    /**
     * 
     * @type {IdentityTypeDto}
     * @memberof UserDto
     */
    'type'?: IdentityTypeDto;
}


/**
 * 
 * @export
 * @interface VisibilityGroupDto
 */
export interface VisibilityGroupDto {
    /**
     * 
     * @type {string}
     * @memberof VisibilityGroupDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VisibilityGroupDto
     */
    'azureGroupId'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof VisibilityGroupDto
     */
    'groupTenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VisibilityGroupDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VisibilityGroupDto
     */
    'usersIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface VisibilitySettingsDto
 */
export interface VisibilitySettingsDto {
    /**
     * 
     * @type {Array<VisibilityGroupDto>}
     * @memberof VisibilitySettingsDto
     */
    'consumingGroups'?: Array<VisibilityGroupDto>;
    /**
     * 
     * @type {Array<VisibilityGroupDto>}
     * @memberof VisibilitySettingsDto
     */
    'managingGroups'?: Array<VisibilityGroupDto>;
}

/**
 * AnalyticsApi - axios parameter creator
 * @export
 */
export const AnalyticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {EventDto} [eventDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackEvent: async (eventDto?: EventDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Analytics/Track`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnalyticsApi - functional programming interface
 * @export
 */
export const AnalyticsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnalyticsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {EventDto} [eventDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trackEvent(eventDto?: EventDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trackEvent(eventDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AnalyticsApi.trackEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AnalyticsApi - factory interface
 * @export
 */
export const AnalyticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnalyticsApiFp(configuration)
    return {
        /**
         * 
         * @param {EventDto} [eventDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackEvent(eventDto?: EventDto, options?: any): AxiosPromise<void> {
            return localVarFp.trackEvent(eventDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnalyticsApi - object-oriented interface
 * @export
 * @class AnalyticsApi
 * @extends {BaseAPI}
 */
export class AnalyticsApi extends BaseAPI {
    /**
     * 
     * @param {EventDto} [eventDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public trackEvent(eventDto?: EventDto, options?: RawAxiosRequestConfig) {
        return AnalyticsApiFp(this.configuration).trackEvent(eventDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CourseProgressesApi - axios parameter creator
 * @export
 */
export const CourseProgressesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canTakeFinalQuiz: async (courseId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('canTakeFinalQuiz', 'courseId', courseId)
            const localVarPath = `/api/CourseProgresses/{courseId}/finalquiz/available`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lang 
         * @param {FinalQuizAnswersDto} [finalQuizAnswersDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeFinalQuiz: async (courseId: string, lang: string, finalQuizAnswersDto?: FinalQuizAnswersDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('completeFinalQuiz', 'courseId', courseId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('completeFinalQuiz', 'lang', lang)
            const localVarPath = `/api/CourseProgresses/{courseId}/finalquiz/complete/{lang}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"lang"}}`, encodeURIComponent(String(lang)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(finalQuizAnswersDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lessonId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeLesson: async (courseId: string, lessonId: string, lang: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('completeLesson', 'courseId', courseId)
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('completeLesson', 'lessonId', lessonId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('completeLesson', 'lang', lang)
            const localVarPath = `/api/CourseProgresses/{courseId}/lessons/{lessonId}/complete/{lang}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)))
                .replace(`{${"lang"}}`, encodeURIComponent(String(lang)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalQuizPassed: async (courseId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('finalQuizPassed', 'courseId', courseId)
            const localVarPath = `/api/CourseProgresses/{courseId}/finalquiz/passed`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertificate: async (courseId: string, lang: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('getCertificate', 'courseId', courseId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('getCertificate', 'lang', lang)
            const localVarPath = `/api/CourseProgresses/{courseId}/getCertificate/{lang}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"lang"}}`, encodeURIComponent(String(lang)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} userId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertificateOf: async (courseId: string, userId: string, lang: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('getCertificateOf', 'courseId', courseId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getCertificateOf', 'userId', userId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('getCertificateOf', 'lang', lang)
            const localVarPath = `/api/CourseProgresses/{courseId}/getCertificateOf/{userId}/{lang}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"lang"}}`, encodeURIComponent(String(lang)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseProgress: async (courseId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('getCourseProgress', 'courseId', courseId)
            const localVarPath = `/api/CourseProgresses/{courseId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFinalQuizAttempts: async (courseId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('getFinalQuizAttempts', 'courseId', courseId)
            const localVarPath = `/api/CourseProgresses/{courseId}/finalquiz/attempts`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserBadges: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CourseProgresses/gamification/userBadges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startFinalQuiz: async (courseId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('startFinalQuiz', 'courseId', courseId)
            const localVarPath = `/api/CourseProgresses/{courseId}/finalquiz/start`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lessonId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewLesson: async (courseId: string, lessonId: string, lang: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('viewLesson', 'courseId', courseId)
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('viewLesson', 'lessonId', lessonId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('viewLesson', 'lang', lang)
            const localVarPath = `/api/CourseProgresses/{courseId}/lessons/{lessonId}/view/{lang}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)))
                .replace(`{${"lang"}}`, encodeURIComponent(String(lang)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CourseProgressesApi - functional programming interface
 * @export
 */
export const CourseProgressesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CourseProgressesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async canTakeFinalQuiz(courseId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.canTakeFinalQuiz(courseId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CourseProgressesApi.canTakeFinalQuiz']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lang 
         * @param {FinalQuizAnswersDto} [finalQuizAnswersDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeFinalQuiz(courseId: string, lang: string, finalQuizAnswersDto?: FinalQuizAnswersDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completeFinalQuiz(courseId, lang, finalQuizAnswersDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CourseProgressesApi.completeFinalQuiz']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lessonId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeLesson(courseId: string, lessonId: string, lang: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completeLesson(courseId, lessonId, lang, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CourseProgressesApi.completeLesson']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async finalQuizPassed(courseId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.finalQuizPassed(courseId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CourseProgressesApi.finalQuizPassed']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCertificate(courseId: string, lang: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCertificate(courseId, lang, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CourseProgressesApi.getCertificate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} userId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCertificateOf(courseId: string, userId: string, lang: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCertificateOf(courseId, userId, lang, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CourseProgressesApi.getCertificateOf']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseProgress(courseId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseProgressDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseProgress(courseId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CourseProgressesApi.getCourseProgress']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFinalQuizAttempts(courseId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LessonProgressDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFinalQuizAttempts(courseId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CourseProgressesApi.getFinalQuizAttempts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserBadges(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BadgeAchievementInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserBadges(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CourseProgressesApi.getUserBadges']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startFinalQuiz(courseId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startFinalQuiz(courseId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CourseProgressesApi.startFinalQuiz']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lessonId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async viewLesson(courseId: string, lessonId: string, lang: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.viewLesson(courseId, lessonId, lang, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CourseProgressesApi.viewLesson']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CourseProgressesApi - factory interface
 * @export
 */
export const CourseProgressesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CourseProgressesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canTakeFinalQuiz(courseId: string, options?: any): AxiosPromise<void> {
            return localVarFp.canTakeFinalQuiz(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lang 
         * @param {FinalQuizAnswersDto} [finalQuizAnswersDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeFinalQuiz(courseId: string, lang: string, finalQuizAnswersDto?: FinalQuizAnswersDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.completeFinalQuiz(courseId, lang, finalQuizAnswersDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lessonId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeLesson(courseId: string, lessonId: string, lang: string, options?: any): AxiosPromise<void> {
            return localVarFp.completeLesson(courseId, lessonId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalQuizPassed(courseId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.finalQuizPassed(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertificate(courseId: string, lang: string, options?: any): AxiosPromise<void> {
            return localVarFp.getCertificate(courseId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} userId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertificateOf(courseId: string, userId: string, lang: string, options?: any): AxiosPromise<void> {
            return localVarFp.getCertificateOf(courseId, userId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseProgress(courseId: string, options?: any): AxiosPromise<CourseProgressDto> {
            return localVarFp.getCourseProgress(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFinalQuizAttempts(courseId: string, options?: any): AxiosPromise<Array<LessonProgressDto>> {
            return localVarFp.getFinalQuizAttempts(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserBadges(options?: any): AxiosPromise<Array<BadgeAchievementInfo>> {
            return localVarFp.getUserBadges(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startFinalQuiz(courseId: string, options?: any): AxiosPromise<void> {
            return localVarFp.startFinalQuiz(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lessonId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewLesson(courseId: string, lessonId: string, lang: string, options?: any): AxiosPromise<void> {
            return localVarFp.viewLesson(courseId, lessonId, lang, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CourseProgressesApi - object-oriented interface
 * @export
 * @class CourseProgressesApi
 * @extends {BaseAPI}
 */
export class CourseProgressesApi extends BaseAPI {
    /**
     * 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseProgressesApi
     */
    public canTakeFinalQuiz(courseId: string, options?: RawAxiosRequestConfig) {
        return CourseProgressesApiFp(this.configuration).canTakeFinalQuiz(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {string} lang 
     * @param {FinalQuizAnswersDto} [finalQuizAnswersDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseProgressesApi
     */
    public completeFinalQuiz(courseId: string, lang: string, finalQuizAnswersDto?: FinalQuizAnswersDto, options?: RawAxiosRequestConfig) {
        return CourseProgressesApiFp(this.configuration).completeFinalQuiz(courseId, lang, finalQuizAnswersDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {string} lessonId 
     * @param {string} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseProgressesApi
     */
    public completeLesson(courseId: string, lessonId: string, lang: string, options?: RawAxiosRequestConfig) {
        return CourseProgressesApiFp(this.configuration).completeLesson(courseId, lessonId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseProgressesApi
     */
    public finalQuizPassed(courseId: string, options?: RawAxiosRequestConfig) {
        return CourseProgressesApiFp(this.configuration).finalQuizPassed(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {string} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseProgressesApi
     */
    public getCertificate(courseId: string, lang: string, options?: RawAxiosRequestConfig) {
        return CourseProgressesApiFp(this.configuration).getCertificate(courseId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {string} userId 
     * @param {string} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseProgressesApi
     */
    public getCertificateOf(courseId: string, userId: string, lang: string, options?: RawAxiosRequestConfig) {
        return CourseProgressesApiFp(this.configuration).getCertificateOf(courseId, userId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseProgressesApi
     */
    public getCourseProgress(courseId: string, options?: RawAxiosRequestConfig) {
        return CourseProgressesApiFp(this.configuration).getCourseProgress(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseProgressesApi
     */
    public getFinalQuizAttempts(courseId: string, options?: RawAxiosRequestConfig) {
        return CourseProgressesApiFp(this.configuration).getFinalQuizAttempts(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseProgressesApi
     */
    public getUserBadges(options?: RawAxiosRequestConfig) {
        return CourseProgressesApiFp(this.configuration).getUserBadges(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseProgressesApi
     */
    public startFinalQuiz(courseId: string, options?: RawAxiosRequestConfig) {
        return CourseProgressesApiFp(this.configuration).startFinalQuiz(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {string} lessonId 
     * @param {string} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseProgressesApi
     */
    public viewLesson(courseId: string, lessonId: string, lang: string, options?: RawAxiosRequestConfig) {
        return CourseProgressesApiFp(this.configuration).viewLesson(courseId, lessonId, lang, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CoursesApi - axios parameter creator
 * @export
 */
export const CoursesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ScratchCourseDto} [scratchCourseDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCourseFromScratch: async (scratchCourseDto?: ScratchCourseDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Courses/addFromScratch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scratchCourseDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lang 
         * @param {AddLessonRequest} [addLessonRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLesson: async (courseId: string, lang: string, addLessonRequest?: AddLessonRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('addLesson', 'courseId', courseId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('addLesson', 'lang', lang)
            const localVarPath = `/api/Courses/{courseId}/{lang}/lessons/add`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"lang"}}`, encodeURIComponent(String(lang)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addLessonRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {ScheduleDto} [scheduleDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSchedule: async (courseId: string, scheduleDto?: ScheduleDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('addSchedule', 'courseId', courseId)
            const localVarPath = `/api/Courses/{courseId}/schedules`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scheduleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveCourse: async (courseId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('archiveCourse', 'courseId', courseId)
            const localVarPath = `/api/Courses/{courseId}/archive`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} scheduleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveSchedule: async (courseId: string, scheduleId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('archiveSchedule', 'courseId', courseId)
            // verify required parameter 'scheduleId' is not null or undefined
            assertParamExists('archiveSchedule', 'scheduleId', scheduleId)
            const localVarPath = `/api/Courses/{courseId}/schedules/{scheduleId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"scheduleId"}}`, encodeURIComponent(String(scheduleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lessonId 
         * @param {ChangeLessonTypeRequestDto} [changeLessonTypeRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeLessonType: async (courseId: string, lessonId: string, changeLessonTypeRequestDto?: ChangeLessonTypeRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('changeLessonType', 'courseId', courseId)
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('changeLessonType', 'lessonId', lessonId)
            const localVarPath = `/api/Courses/{courseId}/lessons/{lessonId}/changeType`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeLessonTypeRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lang 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeOrderByLang: async (courseId: string, lang: string, requestBody?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('changeOrderByLang', 'courseId', courseId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('changeOrderByLang', 'lang', lang)
            const localVarPath = `/api/Courses/{courseId}/{lang}/lessons/order`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"lang"}}`, encodeURIComponent(String(lang)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearCourseError: async (courseId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('clearCourseError', 'courseId', courseId)
            const localVarPath = `/api/Courses/{courseId}/clearError`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clone: async (uuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('clone', 'uuid', uuid)
            const localVarPath = `/api/Courses/{uuid}/clone`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {GenerateLessonRequestDto} [generateLessonRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLesson: async (courseId: string, generateLessonRequestDto?: GenerateLessonRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('createLesson', 'courseId', courseId)
            const localVarPath = `/api/Courses/{courseId}/lessons/create`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateLessonRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCourse: async (courseId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('deleteCourse', 'courseId', courseId)
            const localVarPath = `/api/Courses/{courseId}/delete`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lessonId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLesson: async (courseId: string, lessonId: string, lang: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('deleteLesson', 'courseId', courseId)
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('deleteLesson', 'lessonId', lessonId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('deleteLesson', 'lang', lang)
            const localVarPath = `/api/Courses/{courseId}/{lang}/lessons/{lessonId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)))
                .replace(`{${"lang"}}`, encodeURIComponent(String(lang)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lessonId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLessonAttachment: async (courseId: string, lessonId: string, lang: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('deleteLessonAttachment', 'courseId', courseId)
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('deleteLessonAttachment', 'lessonId', lessonId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('deleteLessonAttachment', 'lang', lang)
            const localVarPath = `/api/Courses/{courseId}/{lang}/lessons/{lessonId}/attachment`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)))
                .replace(`{${"lang"}}`, encodeURIComponent(String(lang)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSearchInfo: async (courseId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('deleteSearchInfo', 'courseId', courseId)
            const localVarPath = `/api/Courses/{courseId}/deleteSearchInfo`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCourseDocument: async (uuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('downloadCourseDocument', 'uuid', uuid)
            const localVarPath = `/api/Courses/{uuid}/downloadCourseDocument`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lang 
         * @param {GlobalCourseSettingsDto} [globalCourseSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editCourseByLang: async (courseId: string, lang: string, globalCourseSettingsDto?: GlobalCourseSettingsDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('editCourseByLang', 'courseId', courseId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('editCourseByLang', 'lang', lang)
            const localVarPath = `/api/Courses/{courseId}/{lang}/edit`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"lang"}}`, encodeURIComponent(String(lang)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(globalCourseSettingsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lang 
         * @param {FinalQuizLessonDto} [finalQuizLessonDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editFinalQuiz: async (courseId: string, lang: string, finalQuizLessonDto?: FinalQuizLessonDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('editFinalQuiz', 'courseId', courseId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('editFinalQuiz', 'lang', lang)
            const localVarPath = `/api/Courses/{courseId}/{lang}/finalquiz/edit`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"lang"}}`, encodeURIComponent(String(lang)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(finalQuizLessonDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lessonId 
         * @param {string} lang 
         * @param {AddLessonRequest} [addLessonRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editLesson: async (courseId: string, lessonId: string, lang: string, addLessonRequest?: AddLessonRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('editLesson', 'courseId', courseId)
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('editLesson', 'lessonId', lessonId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('editLesson', 'lang', lang)
            const localVarPath = `/api/Courses/{courseId}/{lang}/lessons/{lessonId}/edit`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)))
                .replace(`{${"lang"}}`, encodeURIComponent(String(lang)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addLessonRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lessonId 
         * @param {string} lang 
         * @param {FileDto} [fileDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editLessonAttachment: async (courseId: string, lessonId: string, lang: string, fileDto?: FileDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('editLessonAttachment', 'courseId', courseId)
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('editLessonAttachment', 'lessonId', lessonId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('editLessonAttachment', 'lang', lang)
            const localVarPath = `/api/Courses/{courseId}/{lang}/lessons/{lessonId}/attachment`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)))
                .replace(`{${"lang"}}`, encodeURIComponent(String(lang)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} scheduleId 
         * @param {ScheduleDto} [scheduleDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editSchedule: async (courseId: string, scheduleId: string, scheduleDto?: ScheduleDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('editSchedule', 'courseId', courseId)
            // verify required parameter 'scheduleId' is not null or undefined
            assertParamExists('editSchedule', 'scheduleId', scheduleId)
            const localVarPath = `/api/Courses/{courseId}/schedules/{scheduleId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"scheduleId"}}`, encodeURIComponent(String(scheduleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scheduleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emptySearchInfo: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Courses/emptySearchInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GenerateRequestDto} [generateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCourse: async (generateRequestDto?: GenerateRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Courses/generate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateFinalQuiz: async (courseId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('generateFinalQuiz', 'courseId', courseId)
            const localVarPath = `/api/Courses/{courseId}/finalquiz/create`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddLessonSettings: async (courseId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('getAddLessonSettings', 'courseId', courseId)
            const localVarPath = `/api/Courses/{courseId}/addLessonSettings`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArchivedCourses: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Courses/GetArchivedCourses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourse: async (uuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('getCourse', 'uuid', uuid)
            const localVarPath = `/api/Courses/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseByLang: async (uuid: string, lang: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('getCourseByLang', 'uuid', uuid)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('getCourseByLang', 'lang', lang)
            const localVarPath = `/api/Courses/{uuid}/{lang}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)))
                .replace(`{${"lang"}}`, encodeURIComponent(String(lang)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourses: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Courses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoursesProgress: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Courses/GetCoursesProgress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [contentType] 
         * @param {string} [contentDisposition] 
         * @param {{ [key: string]: Array<string>; }} [headers] 
         * @param {number} [length] 
         * @param {string} [name] 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocChapterStyles: async (contentType?: string, contentDisposition?: string, headers?: { [key: string]: Array<string>; }, length?: number, name?: string, fileName?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Courses/getDocStyles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (contentType !== undefined) { 
                localVarFormParams.append('ContentType', contentType as any);
            }
    
            if (contentDisposition !== undefined) { 
                localVarFormParams.append('ContentDisposition', contentDisposition as any);
            }
    
            if (headers !== undefined) { 
                localVarFormParams.append('Headers', new Blob([JSON.stringify(headers)], { type: "application/json", }));
            }
    
            if (length !== undefined) { 
                localVarFormParams.append('Length', length as any);
            }
    
            if (name !== undefined) { 
                localVarFormParams.append('Name', name as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('FileName', fileName as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpectedCourseDuration: async (courseId: string, lang: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('getExpectedCourseDuration', 'courseId', courseId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('getExpectedCourseDuration', 'lang', lang)
            const localVarPath = `/api/Courses/{courseId}/{lang}/expectedDuration`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"lang"}}`, encodeURIComponent(String(lang)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lang 
         * @param {string} lessonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLessonAttachment: async (courseId: string, lang: string, lessonId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('getLessonAttachment', 'courseId', courseId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('getLessonAttachment', 'lang', lang)
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('getLessonAttachment', 'lessonId', lessonId)
            const localVarPath = `/api/Courses/{courseId}/{lang}/lessons/{lessonId}/attachment`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"lang"}}`, encodeURIComponent(String(lang)))
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lang 
         * @param {string} lessonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLessonAudio: async (courseId: string, lang: string, lessonId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('getLessonAudio', 'courseId', courseId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('getLessonAudio', 'lang', lang)
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('getLessonAudio', 'lessonId', lessonId)
            const localVarPath = `/api/Courses/{courseId}/{lang}/lessons/{lessonId}/audio`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"lang"}}`, encodeURIComponent(String(lang)))
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isOpenAiReadyToUse: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Courses/IsOpenAiReadyToUse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} learningProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishCourse: async (courseId: string, learningProviderId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('publishCourse', 'courseId', courseId)
            // verify required parameter 'learningProviderId' is not null or undefined
            assertParamExists('publishCourse', 'learningProviderId', learningProviderId)
            const localVarPath = `/api/Courses/{courseId}/publish/{learningProviderId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"learningProviderId"}}`, encodeURIComponent(String(learningProviderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} learningProviderId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishCourseByLang: async (courseId: string, learningProviderId: string, lang: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('publishCourseByLang', 'courseId', courseId)
            // verify required parameter 'learningProviderId' is not null or undefined
            assertParamExists('publishCourseByLang', 'learningProviderId', learningProviderId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('publishCourseByLang', 'lang', lang)
            const localVarPath = `/api/Courses/{courseId}/{lang}/publish/{learningProviderId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"learningProviderId"}}`, encodeURIComponent(String(learningProviderId)))
                .replace(`{${"lang"}}`, encodeURIComponent(String(lang)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishCourseByLangNoProvider: async (courseId: string, lang: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('publishCourseByLangNoProvider', 'courseId', courseId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('publishCourseByLangNoProvider', 'lang', lang)
            const localVarPath = `/api/Courses/{courseId}/{lang}/publish`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"lang"}}`, encodeURIComponent(String(lang)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishCourseNoProvider: async (courseId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('publishCourseNoProvider', 'courseId', courseId)
            const localVarPath = `/api/Courses/{courseId}/publish`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lessonId 
         * @param {string} lang 
         * @param {string} [contentType] 
         * @param {string} [contentDisposition] 
         * @param {{ [key: string]: Array<string>; }} [headers] 
         * @param {number} [length] 
         * @param {string} [name] 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rawUploadLessonAttachment: async (courseId: string, lessonId: string, lang: string, contentType?: string, contentDisposition?: string, headers?: { [key: string]: Array<string>; }, length?: number, name?: string, fileName?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('rawUploadLessonAttachment', 'courseId', courseId)
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('rawUploadLessonAttachment', 'lessonId', lessonId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('rawUploadLessonAttachment', 'lang', lang)
            const localVarPath = `/api/Courses/{courseId}/{lang}/lessons/{lessonId}/rawUploadAttachment`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)))
                .replace(`{${"lang"}}`, encodeURIComponent(String(lang)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (contentType !== undefined) { 
                localVarFormParams.append('ContentType', contentType as any);
            }
    
            if (contentDisposition !== undefined) { 
                localVarFormParams.append('ContentDisposition', contentDisposition as any);
            }
    
            if (headers !== undefined) { 
                localVarFormParams.append('Headers', new Blob([JSON.stringify(headers)], { type: "application/json", }));
            }
    
            if (length !== undefined) { 
                localVarFormParams.append('Length', length as any);
            }
    
            if (name !== undefined) { 
                localVarFormParams.append('Name', name as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('FileName', fileName as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lang 
         * @param {SearchRequestDto} [searchRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search: async (courseId: string, lang: string, searchRequestDto?: SearchRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('search', 'courseId', courseId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('search', 'lang', lang)
            const localVarPath = `/api/Courses/{courseId}/{lang}/search`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"lang"}}`, encodeURIComponent(String(lang)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} scheduleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSchedule: async (courseId: string, scheduleId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('sendSchedule', 'courseId', courseId)
            // verify required parameter 'scheduleId' is not null or undefined
            assertParamExists('sendSchedule', 'scheduleId', scheduleId)
            const localVarPath = `/api/Courses/{courseId}/schedules/{scheduleId}/send`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"scheduleId"}}`, encodeURIComponent(String(scheduleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {CourseStatusInfoDto} [courseStatusInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCourseStatusInfo: async (courseId: string, courseStatusInfoDto?: CourseStatusInfoDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('setCourseStatusInfo', 'courseId', courseId)
            const localVarPath = `/api/Courses/{courseId}/setStatusInfo`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(courseStatusInfoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startAllSchedules: async (courseId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('startAllSchedules', 'courseId', courseId)
            const localVarPath = `/api/Courses/{courseId}/schedules/startAll`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopAllSchedules: async (courseId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('stopAllSchedules', 'courseId', courseId)
            const localVarPath = `/api/Courses/{courseId}/schedules/stopAll`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenize: async (courseId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('tokenize', 'courseId', courseId)
            const localVarPath = `/api/Courses/{courseId}/tokenize`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenizeAllCourses: async (courseId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('tokenizeAllCourses', 'courseId', courseId)
            const localVarPath = `/api/Courses/tokenizeAllCourses`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {TranslateRequestDto} [translateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translateCourse: async (courseId: string, translateRequestDto?: TranslateRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('translateCourse', 'courseId', courseId)
            const localVarPath = `/api/Courses/{courseId}/translate`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(translateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unarchiveCourse: async (courseId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('unarchiveCourse', 'courseId', courseId)
            const localVarPath = `/api/Courses/{courseId}/unarchive`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} learningProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unpublishCourse: async (courseId: string, learningProviderId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('unpublishCourse', 'courseId', courseId)
            // verify required parameter 'learningProviderId' is not null or undefined
            assertParamExists('unpublishCourse', 'learningProviderId', learningProviderId)
            const localVarPath = `/api/Courses/{courseId}/unpublish/{learningProviderId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"learningProviderId"}}`, encodeURIComponent(String(learningProviderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unpublishCourseNoProvider: async (courseId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('unpublishCourseNoProvider', 'courseId', courseId)
            const localVarPath = `/api/Courses/{courseId}/unpublish`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lessonId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLessonAttachment: async (courseId: string, lessonId: string, lang: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('uploadLessonAttachment', 'courseId', courseId)
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('uploadLessonAttachment', 'lessonId', lessonId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('uploadLessonAttachment', 'lang', lang)
            const localVarPath = `/api/Courses/{courseId}/{lang}/lessons/{lessonId}/uploadAttachment`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)))
                .replace(`{${"lang"}}`, encodeURIComponent(String(lang)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CoursesApi - functional programming interface
 * @export
 */
export const CoursesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CoursesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ScratchCourseDto} [scratchCourseDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCourseFromScratch(scratchCourseDto?: ScratchCourseDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCourseFromScratch(scratchCourseDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.addCourseFromScratch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lang 
         * @param {AddLessonRequest} [addLessonRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addLesson(courseId: string, lang: string, addLessonRequest?: AddLessonRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addLesson(courseId, lang, addLessonRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.addLesson']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {ScheduleDto} [scheduleDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSchedule(courseId: string, scheduleDto?: ScheduleDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSchedule(courseId, scheduleDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.addSchedule']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveCourse(courseId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveCourse(courseId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.archiveCourse']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} scheduleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveSchedule(courseId: string, scheduleId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveSchedule(courseId, scheduleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.archiveSchedule']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lessonId 
         * @param {ChangeLessonTypeRequestDto} [changeLessonTypeRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeLessonType(courseId: string, lessonId: string, changeLessonTypeRequestDto?: ChangeLessonTypeRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeLessonType(courseId, lessonId, changeLessonTypeRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.changeLessonType']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lang 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeOrderByLang(courseId: string, lang: string, requestBody?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeOrderByLang(courseId, lang, requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.changeOrderByLang']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clearCourseError(courseId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clearCourseError(courseId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.clearCourseError']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clone(uuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clone(uuid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.clone']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {GenerateLessonRequestDto} [generateLessonRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLesson(courseId: string, generateLessonRequestDto?: GenerateLessonRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLesson(courseId, generateLessonRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.createLesson']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCourse(courseId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCourse(courseId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.deleteCourse']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lessonId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLesson(courseId: string, lessonId: string, lang: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLesson(courseId, lessonId, lang, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.deleteLesson']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lessonId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLessonAttachment(courseId: string, lessonId: string, lang: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLessonAttachment(courseId, lessonId, lang, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.deleteLessonAttachment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSearchInfo(courseId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSearchInfo(courseId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.deleteSearchInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadCourseDocument(uuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadCourseDocument(uuid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.downloadCourseDocument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lang 
         * @param {GlobalCourseSettingsDto} [globalCourseSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editCourseByLang(courseId: string, lang: string, globalCourseSettingsDto?: GlobalCourseSettingsDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editCourseByLang(courseId, lang, globalCourseSettingsDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.editCourseByLang']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lang 
         * @param {FinalQuizLessonDto} [finalQuizLessonDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editFinalQuiz(courseId: string, lang: string, finalQuizLessonDto?: FinalQuizLessonDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editFinalQuiz(courseId, lang, finalQuizLessonDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.editFinalQuiz']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lessonId 
         * @param {string} lang 
         * @param {AddLessonRequest} [addLessonRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editLesson(courseId: string, lessonId: string, lang: string, addLessonRequest?: AddLessonRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editLesson(courseId, lessonId, lang, addLessonRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.editLesson']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lessonId 
         * @param {string} lang 
         * @param {FileDto} [fileDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editLessonAttachment(courseId: string, lessonId: string, lang: string, fileDto?: FileDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editLessonAttachment(courseId, lessonId, lang, fileDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.editLessonAttachment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} scheduleId 
         * @param {ScheduleDto} [scheduleDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editSchedule(courseId: string, scheduleId: string, scheduleDto?: ScheduleDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editSchedule(courseId, scheduleId, scheduleDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.editSchedule']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emptySearchInfo(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emptySearchInfo(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.emptySearchInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GenerateRequestDto} [generateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateCourse(generateRequestDto?: GenerateRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateCourse(generateRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.generateCourse']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateFinalQuiz(courseId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateFinalQuiz(courseId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.generateFinalQuiz']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAddLessonSettings(courseId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddLessonSettingsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAddLessonSettings(courseId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.getAddLessonSettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArchivedCourses(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CourseV2Dto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getArchivedCourses(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.getArchivedCourses']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourse(uuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseV2Dto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourse(uuid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.getCourse']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseByLang(uuid: string, lang: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseV2Dto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseByLang(uuid, lang, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.getCourseByLang']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourses(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CourseV2Dto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourses(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.getCourses']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCoursesProgress(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: LightCourseProgressDto; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCoursesProgress(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.getCoursesProgress']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [contentType] 
         * @param {string} [contentDisposition] 
         * @param {{ [key: string]: Array<string>; }} [headers] 
         * @param {number} [length] 
         * @param {string} [name] 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocChapterStyles(contentType?: string, contentDisposition?: string, headers?: { [key: string]: Array<string>; }, length?: number, name?: string, fileName?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocChapterStyles(contentType, contentDisposition, headers, length, name, fileName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.getDocChapterStyles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExpectedCourseDuration(courseId: string, lang: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExpectedCourseDuration(courseId, lang, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.getExpectedCourseDuration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lang 
         * @param {string} lessonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLessonAttachment(courseId: string, lang: string, lessonId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLessonAttachment(courseId, lang, lessonId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.getLessonAttachment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lang 
         * @param {string} lessonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLessonAudio(courseId: string, lang: string, lessonId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLessonAudio(courseId, lang, lessonId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.getLessonAudio']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isOpenAiReadyToUse(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isOpenAiReadyToUse(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.isOpenAiReadyToUse']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} learningProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publishCourse(courseId: string, learningProviderId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publishCourse(courseId, learningProviderId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.publishCourse']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} learningProviderId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publishCourseByLang(courseId: string, learningProviderId: string, lang: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publishCourseByLang(courseId, learningProviderId, lang, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.publishCourseByLang']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publishCourseByLangNoProvider(courseId: string, lang: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publishCourseByLangNoProvider(courseId, lang, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.publishCourseByLangNoProvider']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publishCourseNoProvider(courseId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publishCourseNoProvider(courseId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.publishCourseNoProvider']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lessonId 
         * @param {string} lang 
         * @param {string} [contentType] 
         * @param {string} [contentDisposition] 
         * @param {{ [key: string]: Array<string>; }} [headers] 
         * @param {number} [length] 
         * @param {string} [name] 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rawUploadLessonAttachment(courseId: string, lessonId: string, lang: string, contentType?: string, contentDisposition?: string, headers?: { [key: string]: Array<string>; }, length?: number, name?: string, fileName?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rawUploadLessonAttachment(courseId, lessonId, lang, contentType, contentDisposition, headers, length, name, fileName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.rawUploadLessonAttachment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lang 
         * @param {SearchRequestDto} [searchRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async search(courseId: string, lang: string, searchRequestDto?: SearchRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.search(courseId, lang, searchRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.search']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} scheduleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendSchedule(courseId: string, scheduleId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendSchedule(courseId, scheduleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.sendSchedule']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {CourseStatusInfoDto} [courseStatusInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setCourseStatusInfo(courseId: string, courseStatusInfoDto?: CourseStatusInfoDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setCourseStatusInfo(courseId, courseStatusInfoDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.setCourseStatusInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startAllSchedules(courseId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startAllSchedules(courseId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.startAllSchedules']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stopAllSchedules(courseId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stopAllSchedules(courseId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.stopAllSchedules']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tokenize(courseId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tokenize(courseId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.tokenize']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tokenizeAllCourses(courseId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tokenizeAllCourses(courseId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.tokenizeAllCourses']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {TranslateRequestDto} [translateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async translateCourse(courseId: string, translateRequestDto?: TranslateRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.translateCourse(courseId, translateRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.translateCourse']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unarchiveCourse(courseId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unarchiveCourse(courseId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.unarchiveCourse']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} learningProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unpublishCourse(courseId: string, learningProviderId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unpublishCourse(courseId, learningProviderId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.unpublishCourse']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unpublishCourseNoProvider(courseId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unpublishCourseNoProvider(courseId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.unpublishCourseNoProvider']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lessonId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadLessonAttachment(courseId: string, lessonId: string, lang: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadLessonAttachment(courseId, lessonId, lang, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoursesApi.uploadLessonAttachment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CoursesApi - factory interface
 * @export
 */
export const CoursesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CoursesApiFp(configuration)
    return {
        /**
         * 
         * @param {ScratchCourseDto} [scratchCourseDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCourseFromScratch(scratchCourseDto?: ScratchCourseDto, options?: any): AxiosPromise<string> {
            return localVarFp.addCourseFromScratch(scratchCourseDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lang 
         * @param {AddLessonRequest} [addLessonRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLesson(courseId: string, lang: string, addLessonRequest?: AddLessonRequest, options?: any): AxiosPromise<void> {
            return localVarFp.addLesson(courseId, lang, addLessonRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {ScheduleDto} [scheduleDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSchedule(courseId: string, scheduleDto?: ScheduleDto, options?: any): AxiosPromise<ScheduleDto> {
            return localVarFp.addSchedule(courseId, scheduleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveCourse(courseId: string, options?: any): AxiosPromise<void> {
            return localVarFp.archiveCourse(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} scheduleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveSchedule(courseId: string, scheduleId: string, options?: any): AxiosPromise<void> {
            return localVarFp.archiveSchedule(courseId, scheduleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lessonId 
         * @param {ChangeLessonTypeRequestDto} [changeLessonTypeRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeLessonType(courseId: string, lessonId: string, changeLessonTypeRequestDto?: ChangeLessonTypeRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.changeLessonType(courseId, lessonId, changeLessonTypeRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lang 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeOrderByLang(courseId: string, lang: string, requestBody?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.changeOrderByLang(courseId, lang, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearCourseError(courseId: string, options?: any): AxiosPromise<void> {
            return localVarFp.clearCourseError(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clone(uuid: string, options?: any): AxiosPromise<string> {
            return localVarFp.clone(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {GenerateLessonRequestDto} [generateLessonRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLesson(courseId: string, generateLessonRequestDto?: GenerateLessonRequestDto, options?: any): AxiosPromise<string> {
            return localVarFp.createLesson(courseId, generateLessonRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCourse(courseId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCourse(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lessonId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLesson(courseId: string, lessonId: string, lang: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteLesson(courseId, lessonId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lessonId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLessonAttachment(courseId: string, lessonId: string, lang: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteLessonAttachment(courseId, lessonId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSearchInfo(courseId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSearchInfo(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCourseDocument(uuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.downloadCourseDocument(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lang 
         * @param {GlobalCourseSettingsDto} [globalCourseSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editCourseByLang(courseId: string, lang: string, globalCourseSettingsDto?: GlobalCourseSettingsDto, options?: any): AxiosPromise<void> {
            return localVarFp.editCourseByLang(courseId, lang, globalCourseSettingsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lang 
         * @param {FinalQuizLessonDto} [finalQuizLessonDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editFinalQuiz(courseId: string, lang: string, finalQuizLessonDto?: FinalQuizLessonDto, options?: any): AxiosPromise<void> {
            return localVarFp.editFinalQuiz(courseId, lang, finalQuizLessonDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lessonId 
         * @param {string} lang 
         * @param {AddLessonRequest} [addLessonRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editLesson(courseId: string, lessonId: string, lang: string, addLessonRequest?: AddLessonRequest, options?: any): AxiosPromise<void> {
            return localVarFp.editLesson(courseId, lessonId, lang, addLessonRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lessonId 
         * @param {string} lang 
         * @param {FileDto} [fileDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editLessonAttachment(courseId: string, lessonId: string, lang: string, fileDto?: FileDto, options?: any): AxiosPromise<void> {
            return localVarFp.editLessonAttachment(courseId, lessonId, lang, fileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} scheduleId 
         * @param {ScheduleDto} [scheduleDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editSchedule(courseId: string, scheduleId: string, scheduleDto?: ScheduleDto, options?: any): AxiosPromise<void> {
            return localVarFp.editSchedule(courseId, scheduleId, scheduleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emptySearchInfo(options?: any): AxiosPromise<void> {
            return localVarFp.emptySearchInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GenerateRequestDto} [generateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCourse(generateRequestDto?: GenerateRequestDto, options?: any): AxiosPromise<string> {
            return localVarFp.generateCourse(generateRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateFinalQuiz(courseId: string, options?: any): AxiosPromise<void> {
            return localVarFp.generateFinalQuiz(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddLessonSettings(courseId: string, options?: any): AxiosPromise<AddLessonSettingsDto> {
            return localVarFp.getAddLessonSettings(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArchivedCourses(options?: any): AxiosPromise<Array<CourseV2Dto>> {
            return localVarFp.getArchivedCourses(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourse(uuid: string, options?: any): AxiosPromise<CourseV2Dto> {
            return localVarFp.getCourse(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseByLang(uuid: string, lang: string, options?: any): AxiosPromise<CourseV2Dto> {
            return localVarFp.getCourseByLang(uuid, lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourses(options?: any): AxiosPromise<Array<CourseV2Dto>> {
            return localVarFp.getCourses(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoursesProgress(options?: any): AxiosPromise<{ [key: string]: LightCourseProgressDto; }> {
            return localVarFp.getCoursesProgress(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [contentType] 
         * @param {string} [contentDisposition] 
         * @param {{ [key: string]: Array<string>; }} [headers] 
         * @param {number} [length] 
         * @param {string} [name] 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocChapterStyles(contentType?: string, contentDisposition?: string, headers?: { [key: string]: Array<string>; }, length?: number, name?: string, fileName?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getDocChapterStyles(contentType, contentDisposition, headers, length, name, fileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpectedCourseDuration(courseId: string, lang: string, options?: any): AxiosPromise<void> {
            return localVarFp.getExpectedCourseDuration(courseId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lang 
         * @param {string} lessonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLessonAttachment(courseId: string, lang: string, lessonId: string, options?: any): AxiosPromise<FileDto> {
            return localVarFp.getLessonAttachment(courseId, lang, lessonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lang 
         * @param {string} lessonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLessonAudio(courseId: string, lang: string, lessonId: string, options?: any): AxiosPromise<void> {
            return localVarFp.getLessonAudio(courseId, lang, lessonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isOpenAiReadyToUse(options?: any): AxiosPromise<boolean> {
            return localVarFp.isOpenAiReadyToUse(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} learningProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishCourse(courseId: string, learningProviderId: string, options?: any): AxiosPromise<void> {
            return localVarFp.publishCourse(courseId, learningProviderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} learningProviderId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishCourseByLang(courseId: string, learningProviderId: string, lang: string, options?: any): AxiosPromise<void> {
            return localVarFp.publishCourseByLang(courseId, learningProviderId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishCourseByLangNoProvider(courseId: string, lang: string, options?: any): AxiosPromise<void> {
            return localVarFp.publishCourseByLangNoProvider(courseId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishCourseNoProvider(courseId: string, options?: any): AxiosPromise<void> {
            return localVarFp.publishCourseNoProvider(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lessonId 
         * @param {string} lang 
         * @param {string} [contentType] 
         * @param {string} [contentDisposition] 
         * @param {{ [key: string]: Array<string>; }} [headers] 
         * @param {number} [length] 
         * @param {string} [name] 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rawUploadLessonAttachment(courseId: string, lessonId: string, lang: string, contentType?: string, contentDisposition?: string, headers?: { [key: string]: Array<string>; }, length?: number, name?: string, fileName?: string, options?: any): AxiosPromise<void> {
            return localVarFp.rawUploadLessonAttachment(courseId, lessonId, lang, contentType, contentDisposition, headers, length, name, fileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lang 
         * @param {SearchRequestDto} [searchRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search(courseId: string, lang: string, searchRequestDto?: SearchRequestDto, options?: any): AxiosPromise<SearchResult> {
            return localVarFp.search(courseId, lang, searchRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} scheduleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSchedule(courseId: string, scheduleId: string, options?: any): AxiosPromise<void> {
            return localVarFp.sendSchedule(courseId, scheduleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {CourseStatusInfoDto} [courseStatusInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCourseStatusInfo(courseId: string, courseStatusInfoDto?: CourseStatusInfoDto, options?: any): AxiosPromise<void> {
            return localVarFp.setCourseStatusInfo(courseId, courseStatusInfoDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startAllSchedules(courseId: string, options?: any): AxiosPromise<void> {
            return localVarFp.startAllSchedules(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopAllSchedules(courseId: string, options?: any): AxiosPromise<void> {
            return localVarFp.stopAllSchedules(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenize(courseId: string, options?: any): AxiosPromise<void> {
            return localVarFp.tokenize(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenizeAllCourses(courseId: string, options?: any): AxiosPromise<void> {
            return localVarFp.tokenizeAllCourses(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {TranslateRequestDto} [translateRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translateCourse(courseId: string, translateRequestDto?: TranslateRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.translateCourse(courseId, translateRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unarchiveCourse(courseId: string, options?: any): AxiosPromise<void> {
            return localVarFp.unarchiveCourse(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} learningProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unpublishCourse(courseId: string, learningProviderId: string, options?: any): AxiosPromise<void> {
            return localVarFp.unpublishCourse(courseId, learningProviderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unpublishCourseNoProvider(courseId: string, options?: any): AxiosPromise<void> {
            return localVarFp.unpublishCourseNoProvider(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lessonId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLessonAttachment(courseId: string, lessonId: string, lang: string, options?: any): AxiosPromise<void> {
            return localVarFp.uploadLessonAttachment(courseId, lessonId, lang, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CoursesApi - object-oriented interface
 * @export
 * @class CoursesApi
 * @extends {BaseAPI}
 */
export class CoursesApi extends BaseAPI {
    /**
     * 
     * @param {ScratchCourseDto} [scratchCourseDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public addCourseFromScratch(scratchCourseDto?: ScratchCourseDto, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).addCourseFromScratch(scratchCourseDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {string} lang 
     * @param {AddLessonRequest} [addLessonRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public addLesson(courseId: string, lang: string, addLessonRequest?: AddLessonRequest, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).addLesson(courseId, lang, addLessonRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {ScheduleDto} [scheduleDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public addSchedule(courseId: string, scheduleDto?: ScheduleDto, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).addSchedule(courseId, scheduleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public archiveCourse(courseId: string, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).archiveCourse(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {string} scheduleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public archiveSchedule(courseId: string, scheduleId: string, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).archiveSchedule(courseId, scheduleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {string} lessonId 
     * @param {ChangeLessonTypeRequestDto} [changeLessonTypeRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public changeLessonType(courseId: string, lessonId: string, changeLessonTypeRequestDto?: ChangeLessonTypeRequestDto, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).changeLessonType(courseId, lessonId, changeLessonTypeRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {string} lang 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public changeOrderByLang(courseId: string, lang: string, requestBody?: Array<string>, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).changeOrderByLang(courseId, lang, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public clearCourseError(courseId: string, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).clearCourseError(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public clone(uuid: string, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).clone(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {GenerateLessonRequestDto} [generateLessonRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public createLesson(courseId: string, generateLessonRequestDto?: GenerateLessonRequestDto, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).createLesson(courseId, generateLessonRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public deleteCourse(courseId: string, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).deleteCourse(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {string} lessonId 
     * @param {string} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public deleteLesson(courseId: string, lessonId: string, lang: string, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).deleteLesson(courseId, lessonId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {string} lessonId 
     * @param {string} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public deleteLessonAttachment(courseId: string, lessonId: string, lang: string, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).deleteLessonAttachment(courseId, lessonId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public deleteSearchInfo(courseId: string, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).deleteSearchInfo(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public downloadCourseDocument(uuid: string, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).downloadCourseDocument(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {string} lang 
     * @param {GlobalCourseSettingsDto} [globalCourseSettingsDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public editCourseByLang(courseId: string, lang: string, globalCourseSettingsDto?: GlobalCourseSettingsDto, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).editCourseByLang(courseId, lang, globalCourseSettingsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {string} lang 
     * @param {FinalQuizLessonDto} [finalQuizLessonDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public editFinalQuiz(courseId: string, lang: string, finalQuizLessonDto?: FinalQuizLessonDto, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).editFinalQuiz(courseId, lang, finalQuizLessonDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {string} lessonId 
     * @param {string} lang 
     * @param {AddLessonRequest} [addLessonRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public editLesson(courseId: string, lessonId: string, lang: string, addLessonRequest?: AddLessonRequest, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).editLesson(courseId, lessonId, lang, addLessonRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {string} lessonId 
     * @param {string} lang 
     * @param {FileDto} [fileDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public editLessonAttachment(courseId: string, lessonId: string, lang: string, fileDto?: FileDto, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).editLessonAttachment(courseId, lessonId, lang, fileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {string} scheduleId 
     * @param {ScheduleDto} [scheduleDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public editSchedule(courseId: string, scheduleId: string, scheduleDto?: ScheduleDto, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).editSchedule(courseId, scheduleId, scheduleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public emptySearchInfo(options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).emptySearchInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GenerateRequestDto} [generateRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public generateCourse(generateRequestDto?: GenerateRequestDto, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).generateCourse(generateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public generateFinalQuiz(courseId: string, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).generateFinalQuiz(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public getAddLessonSettings(courseId: string, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).getAddLessonSettings(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public getArchivedCourses(options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).getArchivedCourses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public getCourse(uuid: string, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).getCourse(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {string} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public getCourseByLang(uuid: string, lang: string, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).getCourseByLang(uuid, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public getCourses(options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).getCourses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public getCoursesProgress(options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).getCoursesProgress(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [contentType] 
     * @param {string} [contentDisposition] 
     * @param {{ [key: string]: Array<string>; }} [headers] 
     * @param {number} [length] 
     * @param {string} [name] 
     * @param {string} [fileName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public getDocChapterStyles(contentType?: string, contentDisposition?: string, headers?: { [key: string]: Array<string>; }, length?: number, name?: string, fileName?: string, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).getDocChapterStyles(contentType, contentDisposition, headers, length, name, fileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {string} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public getExpectedCourseDuration(courseId: string, lang: string, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).getExpectedCourseDuration(courseId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {string} lang 
     * @param {string} lessonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public getLessonAttachment(courseId: string, lang: string, lessonId: string, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).getLessonAttachment(courseId, lang, lessonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {string} lang 
     * @param {string} lessonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public getLessonAudio(courseId: string, lang: string, lessonId: string, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).getLessonAudio(courseId, lang, lessonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public isOpenAiReadyToUse(options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).isOpenAiReadyToUse(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {string} learningProviderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public publishCourse(courseId: string, learningProviderId: string, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).publishCourse(courseId, learningProviderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {string} learningProviderId 
     * @param {string} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public publishCourseByLang(courseId: string, learningProviderId: string, lang: string, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).publishCourseByLang(courseId, learningProviderId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {string} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public publishCourseByLangNoProvider(courseId: string, lang: string, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).publishCourseByLangNoProvider(courseId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public publishCourseNoProvider(courseId: string, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).publishCourseNoProvider(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {string} lessonId 
     * @param {string} lang 
     * @param {string} [contentType] 
     * @param {string} [contentDisposition] 
     * @param {{ [key: string]: Array<string>; }} [headers] 
     * @param {number} [length] 
     * @param {string} [name] 
     * @param {string} [fileName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public rawUploadLessonAttachment(courseId: string, lessonId: string, lang: string, contentType?: string, contentDisposition?: string, headers?: { [key: string]: Array<string>; }, length?: number, name?: string, fileName?: string, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).rawUploadLessonAttachment(courseId, lessonId, lang, contentType, contentDisposition, headers, length, name, fileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {string} lang 
     * @param {SearchRequestDto} [searchRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public search(courseId: string, lang: string, searchRequestDto?: SearchRequestDto, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).search(courseId, lang, searchRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {string} scheduleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public sendSchedule(courseId: string, scheduleId: string, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).sendSchedule(courseId, scheduleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {CourseStatusInfoDto} [courseStatusInfoDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public setCourseStatusInfo(courseId: string, courseStatusInfoDto?: CourseStatusInfoDto, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).setCourseStatusInfo(courseId, courseStatusInfoDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public startAllSchedules(courseId: string, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).startAllSchedules(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public stopAllSchedules(courseId: string, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).stopAllSchedules(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public tokenize(courseId: string, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).tokenize(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public tokenizeAllCourses(courseId: string, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).tokenizeAllCourses(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {TranslateRequestDto} [translateRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public translateCourse(courseId: string, translateRequestDto?: TranslateRequestDto, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).translateCourse(courseId, translateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public unarchiveCourse(courseId: string, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).unarchiveCourse(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {string} learningProviderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public unpublishCourse(courseId: string, learningProviderId: string, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).unpublishCourse(courseId, learningProviderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public unpublishCourseNoProvider(courseId: string, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).unpublishCourseNoProvider(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {string} lessonId 
     * @param {string} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public uploadLessonAttachment(courseId: string, lessonId: string, lang: string, options?: RawAxiosRequestConfig) {
        return CoursesApiFp(this.configuration).uploadLessonAttachment(courseId, lessonId, lang, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LmsApi - axios parameter creator
 * @export
 */
export const LmsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddWhitelistedDomainDto} [addWhitelistedDomainDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWhitelistedDomain: async (addWhitelistedDomainDto?: AddWhitelistedDomainDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Lms/whitelistDomain`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addWhitelistedDomainDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CheckLmsDto} [checkLmsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkLms: async (checkLmsDto?: CheckLmsDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Lms/CheckLms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkLmsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {ScormStandard} scormVersion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportScorm: async (courseId: string, scormVersion: ScormStandard, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('exportScorm', 'courseId', courseId)
            // verify required parameter 'scormVersion' is not null or undefined
            assertParamExists('exportScorm', 'scormVersion', scormVersion)
            const localVarPath = `/api/Lms/{courseId}/exportScorm/{scormVersion}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"scormVersion"}}`, encodeURIComponent(String(scormVersion)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {ScormStandard} scormVersion 
         * @param {AdvancedExportScormSettingsDto} [advancedExportScormSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportScormAdvanced: async (courseId: string, scormVersion: ScormStandard, advancedExportScormSettingsDto?: AdvancedExportScormSettingsDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('exportScormAdvanced', 'courseId', courseId)
            // verify required parameter 'scormVersion' is not null or undefined
            assertParamExists('exportScormAdvanced', 'scormVersion', scormVersion)
            const localVarPath = `/api/Lms/{courseId}/exportScorm/advanced/{scormVersion}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"scormVersion"}}`, encodeURIComponent(String(scormVersion)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(advancedExportScormSettingsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLmsStatus: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Lms/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setWhitelistedDomains: async (requestBody?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Lms/setWhitelistedDomains`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LmsApi - functional programming interface
 * @export
 */
export const LmsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LmsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddWhitelistedDomainDto} [addWhitelistedDomainDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addWhitelistedDomain(addWhitelistedDomainDto?: AddWhitelistedDomainDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addWhitelistedDomain(addWhitelistedDomainDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LmsApi.addWhitelistedDomain']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CheckLmsDto} [checkLmsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkLms(checkLmsDto?: CheckLmsDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkLms(checkLmsDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LmsApi.checkLms']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {ScormStandard} scormVersion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportScorm(courseId: string, scormVersion: ScormStandard, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportScorm(courseId, scormVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LmsApi.exportScorm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {ScormStandard} scormVersion 
         * @param {AdvancedExportScormSettingsDto} [advancedExportScormSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportScormAdvanced(courseId: string, scormVersion: ScormStandard, advancedExportScormSettingsDto?: AdvancedExportScormSettingsDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportScormAdvanced(courseId, scormVersion, advancedExportScormSettingsDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LmsApi.exportScormAdvanced']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLmsStatus(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LmsStatusInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLmsStatus(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LmsApi.getLmsStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setWhitelistedDomains(requestBody?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setWhitelistedDomains(requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LmsApi.setWhitelistedDomains']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LmsApi - factory interface
 * @export
 */
export const LmsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LmsApiFp(configuration)
    return {
        /**
         * 
         * @param {AddWhitelistedDomainDto} [addWhitelistedDomainDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWhitelistedDomain(addWhitelistedDomainDto?: AddWhitelistedDomainDto, options?: any): AxiosPromise<void> {
            return localVarFp.addWhitelistedDomain(addWhitelistedDomainDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CheckLmsDto} [checkLmsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkLms(checkLmsDto?: CheckLmsDto, options?: any): AxiosPromise<string> {
            return localVarFp.checkLms(checkLmsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {ScormStandard} scormVersion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportScorm(courseId: string, scormVersion: ScormStandard, options?: any): AxiosPromise<void> {
            return localVarFp.exportScorm(courseId, scormVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {ScormStandard} scormVersion 
         * @param {AdvancedExportScormSettingsDto} [advancedExportScormSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportScormAdvanced(courseId: string, scormVersion: ScormStandard, advancedExportScormSettingsDto?: AdvancedExportScormSettingsDto, options?: any): AxiosPromise<void> {
            return localVarFp.exportScormAdvanced(courseId, scormVersion, advancedExportScormSettingsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLmsStatus(options?: any): AxiosPromise<LmsStatusInfoDto> {
            return localVarFp.getLmsStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setWhitelistedDomains(requestBody?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.setWhitelistedDomains(requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LmsApi - object-oriented interface
 * @export
 * @class LmsApi
 * @extends {BaseAPI}
 */
export class LmsApi extends BaseAPI {
    /**
     * 
     * @param {AddWhitelistedDomainDto} [addWhitelistedDomainDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LmsApi
     */
    public addWhitelistedDomain(addWhitelistedDomainDto?: AddWhitelistedDomainDto, options?: RawAxiosRequestConfig) {
        return LmsApiFp(this.configuration).addWhitelistedDomain(addWhitelistedDomainDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CheckLmsDto} [checkLmsDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LmsApi
     */
    public checkLms(checkLmsDto?: CheckLmsDto, options?: RawAxiosRequestConfig) {
        return LmsApiFp(this.configuration).checkLms(checkLmsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {ScormStandard} scormVersion 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LmsApi
     */
    public exportScorm(courseId: string, scormVersion: ScormStandard, options?: RawAxiosRequestConfig) {
        return LmsApiFp(this.configuration).exportScorm(courseId, scormVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {ScormStandard} scormVersion 
     * @param {AdvancedExportScormSettingsDto} [advancedExportScormSettingsDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LmsApi
     */
    public exportScormAdvanced(courseId: string, scormVersion: ScormStandard, advancedExportScormSettingsDto?: AdvancedExportScormSettingsDto, options?: RawAxiosRequestConfig) {
        return LmsApiFp(this.configuration).exportScormAdvanced(courseId, scormVersion, advancedExportScormSettingsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LmsApi
     */
    public getLmsStatus(options?: RawAxiosRequestConfig) {
        return LmsApiFp(this.configuration).getLmsStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LmsApi
     */
    public setWhitelistedDomains(requestBody?: Array<string>, options?: RawAxiosRequestConfig) {
        return LmsApiFp(this.configuration).setWhitelistedDomains(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SettingsApi - axios parameter creator
 * @export
 */
export const SettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CourseCategoryDto} [courseCategoryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCategory: async (courseCategoryDto?: CourseCategoryDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Settings/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(courseCategoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary CreateEditVisibilityGroup
         * @param {VisibilityGroupDto} [visibilityGroupDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsCreateEditVisibilityGroupPost: async (visibilityGroupDto?: VisibilityGroupDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Settings/CreateEditVisibilityGroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(visibilityGroupDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary CreateUpdateBadge
         * @param {GamificationBadgeDto} [gamificationBadgeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsCreateUpdateBadgePost: async (gamificationBadgeDto?: GamificationBadgeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Settings/CreateUpdateBadge`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gamificationBadgeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary DeleteVisibilityGroup/{id}
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsDeleteVisibilityGroupDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSettingsDeleteVisibilityGroupDelete', 'id', id)
            const localVarPath = `/api/Settings/DeleteVisibilityGroup`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetSettings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetBadges
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsGetBadgesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Settings/GetBadges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetVisibilityGroups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsGetVisibilityGroupsPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Settings/GetVisibilityGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetLearningProviders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsLearningProvidersGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Settings/LearningProviders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} learningProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsLearningProvidersLearningProviderIdLearningContentsGet: async (learningProviderId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'learningProviderId' is not null or undefined
            assertParamExists('apiSettingsLearningProvidersLearningProviderIdLearningContentsGet', 'learningProviderId', learningProviderId)
            const localVarPath = `/api/Settings/LearningProviders/{learningProviderId}/LearningContents`
                .replace(`{${"learningProviderId"}}`, encodeURIComponent(String(learningProviderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary CreateLearningProviders
         * @param {LearningProviderDto} [learningProviderDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsLearningProvidersPost: async (learningProviderDto?: LearningProviderDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Settings/LearningProviders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(learningProviderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary OverrideBadges
         * @param {Array<GamificationBadgeDto>} [gamificationBadgeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsOverrideBadgesPost: async (gamificationBadgeDto?: Array<GamificationBadgeDto>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Settings/OverrideBadges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gamificationBadgeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary UploadCompanyLogo
         * @param {string} [contentType] 
         * @param {string} [contentDisposition] 
         * @param {{ [key: string]: Array<string>; }} [headers] 
         * @param {number} [length] 
         * @param {string} [name] 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsUploadCompanyLogoPost: async (contentType?: string, contentDisposition?: string, headers?: { [key: string]: Array<string>; }, length?: number, name?: string, fileName?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Settings/UploadCompanyLogo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (contentType !== undefined) { 
                localVarFormParams.append('ContentType', contentType as any);
            }
    
            if (contentDisposition !== undefined) { 
                localVarFormParams.append('ContentDisposition', contentDisposition as any);
            }
    
            if (headers !== undefined) { 
                localVarFormParams.append('Headers', new Blob([JSON.stringify(headers)], { type: "application/json", }));
            }
    
            if (length !== undefined) { 
                localVarFormParams.append('Length', length as any);
            }
    
            if (name !== undefined) { 
                localVarFormParams.append('Name', name as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('FileName', fileName as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveCategory: async (categoryId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('archiveCategory', 'categoryId', categoryId)
            const localVarPath = `/api/Settings/categories/{categoryId}/archive`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} categoryId 
         * @param {CourseCategoryDto} [courseCategoryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editCategory: async (categoryId: string, courseCategoryDto?: CourseCategoryDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('editCategory', 'categoryId', categoryId)
            const localVarPath = `/api/Settings/categories/{categoryId}/edit`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(courseCategoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCategories: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Settings/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyLogo: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Settings/GetCompanyLogo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeZones: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Settings/TimeZones`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<CourseCategoryDto>} [courseCategoryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        overrideCategories: async (courseCategoryDto?: Array<CourseCategoryDto>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Settings/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(courseCategoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingsApi - functional programming interface
 * @export
 */
export const SettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CourseCategoryDto} [courseCategoryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCategory(courseCategoryDto?: CourseCategoryDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CourseCategoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCategory(courseCategoryDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SettingsApi.addCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary CreateEditVisibilityGroup
         * @param {VisibilityGroupDto} [visibilityGroupDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettingsCreateEditVisibilityGroupPost(visibilityGroupDto?: VisibilityGroupDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettingsCreateEditVisibilityGroupPost(visibilityGroupDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SettingsApi.apiSettingsCreateEditVisibilityGroupPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary CreateUpdateBadge
         * @param {GamificationBadgeDto} [gamificationBadgeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettingsCreateUpdateBadgePost(gamificationBadgeDto?: GamificationBadgeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettingsCreateUpdateBadgePost(gamificationBadgeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SettingsApi.apiSettingsCreateUpdateBadgePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary DeleteVisibilityGroup/{id}
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettingsDeleteVisibilityGroupDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettingsDeleteVisibilityGroupDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SettingsApi.apiSettingsDeleteVisibilityGroupDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary GetSettings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettingsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettingsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SettingsApi.apiSettingsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary GetBadges
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettingsGetBadgesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GamificationBadgeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettingsGetBadgesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SettingsApi.apiSettingsGetBadgesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary GetVisibilityGroups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettingsGetVisibilityGroupsPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VisibilityGroupDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettingsGetVisibilityGroupsPost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SettingsApi.apiSettingsGetVisibilityGroupsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary GetLearningProviders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettingsLearningProvidersGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LearningProviderDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettingsLearningProvidersGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SettingsApi.apiSettingsLearningProvidersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} learningProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettingsLearningProvidersLearningProviderIdLearningContentsGet(learningProviderId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LearningContentDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettingsLearningProvidersLearningProviderIdLearningContentsGet(learningProviderId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SettingsApi.apiSettingsLearningProvidersLearningProviderIdLearningContentsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary CreateLearningProviders
         * @param {LearningProviderDto} [learningProviderDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettingsLearningProvidersPost(learningProviderDto?: LearningProviderDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LearningProviderDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettingsLearningProvidersPost(learningProviderDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SettingsApi.apiSettingsLearningProvidersPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary OverrideBadges
         * @param {Array<GamificationBadgeDto>} [gamificationBadgeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettingsOverrideBadgesPost(gamificationBadgeDto?: Array<GamificationBadgeDto>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettingsOverrideBadgesPost(gamificationBadgeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SettingsApi.apiSettingsOverrideBadgesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary UploadCompanyLogo
         * @param {string} [contentType] 
         * @param {string} [contentDisposition] 
         * @param {{ [key: string]: Array<string>; }} [headers] 
         * @param {number} [length] 
         * @param {string} [name] 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettingsUploadCompanyLogoPost(contentType?: string, contentDisposition?: string, headers?: { [key: string]: Array<string>; }, length?: number, name?: string, fileName?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettingsUploadCompanyLogoPost(contentType, contentDisposition, headers, length, name, fileName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SettingsApi.apiSettingsUploadCompanyLogoPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveCategory(categoryId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CourseCategoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveCategory(categoryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SettingsApi.archiveCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} categoryId 
         * @param {CourseCategoryDto} [courseCategoryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editCategory(categoryId: string, courseCategoryDto?: CourseCategoryDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CourseCategoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editCategory(categoryId, courseCategoryDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SettingsApi.editCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCategories(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CourseCategoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCategories(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SettingsApi.getAllCategories']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyLogo(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyLogo(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SettingsApi.getCompanyLogo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTimeZones(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TimeZoneDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTimeZones(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SettingsApi.getTimeZones']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<CourseCategoryDto>} [courseCategoryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async overrideCategories(courseCategoryDto?: Array<CourseCategoryDto>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.overrideCategories(courseCategoryDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SettingsApi.overrideCategories']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SettingsApi - factory interface
 * @export
 */
export const SettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingsApiFp(configuration)
    return {
        /**
         * 
         * @param {CourseCategoryDto} [courseCategoryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCategory(courseCategoryDto?: CourseCategoryDto, options?: any): AxiosPromise<Array<CourseCategoryDto>> {
            return localVarFp.addCategory(courseCategoryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary CreateEditVisibilityGroup
         * @param {VisibilityGroupDto} [visibilityGroupDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsCreateEditVisibilityGroupPost(visibilityGroupDto?: VisibilityGroupDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiSettingsCreateEditVisibilityGroupPost(visibilityGroupDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary CreateUpdateBadge
         * @param {GamificationBadgeDto} [gamificationBadgeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsCreateUpdateBadgePost(gamificationBadgeDto?: GamificationBadgeDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiSettingsCreateUpdateBadgePost(gamificationBadgeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary DeleteVisibilityGroup/{id}
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsDeleteVisibilityGroupDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiSettingsDeleteVisibilityGroupDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetSettings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsGet(options?: any): AxiosPromise<SettingsDto> {
            return localVarFp.apiSettingsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetBadges
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsGetBadgesGet(options?: any): AxiosPromise<Array<GamificationBadgeDto>> {
            return localVarFp.apiSettingsGetBadgesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetVisibilityGroups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsGetVisibilityGroupsPost(options?: any): AxiosPromise<Array<VisibilityGroupDto>> {
            return localVarFp.apiSettingsGetVisibilityGroupsPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetLearningProviders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsLearningProvidersGet(options?: any): AxiosPromise<Array<LearningProviderDto>> {
            return localVarFp.apiSettingsLearningProvidersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} learningProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsLearningProvidersLearningProviderIdLearningContentsGet(learningProviderId: string, options?: any): AxiosPromise<Array<LearningContentDto>> {
            return localVarFp.apiSettingsLearningProvidersLearningProviderIdLearningContentsGet(learningProviderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary CreateLearningProviders
         * @param {LearningProviderDto} [learningProviderDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsLearningProvidersPost(learningProviderDto?: LearningProviderDto, options?: any): AxiosPromise<LearningProviderDto> {
            return localVarFp.apiSettingsLearningProvidersPost(learningProviderDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary OverrideBadges
         * @param {Array<GamificationBadgeDto>} [gamificationBadgeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsOverrideBadgesPost(gamificationBadgeDto?: Array<GamificationBadgeDto>, options?: any): AxiosPromise<void> {
            return localVarFp.apiSettingsOverrideBadgesPost(gamificationBadgeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary UploadCompanyLogo
         * @param {string} [contentType] 
         * @param {string} [contentDisposition] 
         * @param {{ [key: string]: Array<string>; }} [headers] 
         * @param {number} [length] 
         * @param {string} [name] 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsUploadCompanyLogoPost(contentType?: string, contentDisposition?: string, headers?: { [key: string]: Array<string>; }, length?: number, name?: string, fileName?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiSettingsUploadCompanyLogoPost(contentType, contentDisposition, headers, length, name, fileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveCategory(categoryId: string, options?: any): AxiosPromise<Array<CourseCategoryDto>> {
            return localVarFp.archiveCategory(categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} categoryId 
         * @param {CourseCategoryDto} [courseCategoryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editCategory(categoryId: string, courseCategoryDto?: CourseCategoryDto, options?: any): AxiosPromise<Array<CourseCategoryDto>> {
            return localVarFp.editCategory(categoryId, courseCategoryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCategories(options?: any): AxiosPromise<Array<CourseCategoryDto>> {
            return localVarFp.getAllCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyLogo(options?: any): AxiosPromise<void> {
            return localVarFp.getCompanyLogo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeZones(options?: any): AxiosPromise<Array<TimeZoneDto>> {
            return localVarFp.getTimeZones(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<CourseCategoryDto>} [courseCategoryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        overrideCategories(courseCategoryDto?: Array<CourseCategoryDto>, options?: any): AxiosPromise<void> {
            return localVarFp.overrideCategories(courseCategoryDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettingsApi - object-oriented interface
 * @export
 * @class SettingsApi
 * @extends {BaseAPI}
 */
export class SettingsApi extends BaseAPI {
    /**
     * 
     * @param {CourseCategoryDto} [courseCategoryDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public addCategory(courseCategoryDto?: CourseCategoryDto, options?: RawAxiosRequestConfig) {
        return SettingsApiFp(this.configuration).addCategory(courseCategoryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary CreateEditVisibilityGroup
     * @param {VisibilityGroupDto} [visibilityGroupDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiSettingsCreateEditVisibilityGroupPost(visibilityGroupDto?: VisibilityGroupDto, options?: RawAxiosRequestConfig) {
        return SettingsApiFp(this.configuration).apiSettingsCreateEditVisibilityGroupPost(visibilityGroupDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary CreateUpdateBadge
     * @param {GamificationBadgeDto} [gamificationBadgeDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiSettingsCreateUpdateBadgePost(gamificationBadgeDto?: GamificationBadgeDto, options?: RawAxiosRequestConfig) {
        return SettingsApiFp(this.configuration).apiSettingsCreateUpdateBadgePost(gamificationBadgeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary DeleteVisibilityGroup/{id}
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiSettingsDeleteVisibilityGroupDelete(id: string, options?: RawAxiosRequestConfig) {
        return SettingsApiFp(this.configuration).apiSettingsDeleteVisibilityGroupDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetSettings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiSettingsGet(options?: RawAxiosRequestConfig) {
        return SettingsApiFp(this.configuration).apiSettingsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetBadges
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiSettingsGetBadgesGet(options?: RawAxiosRequestConfig) {
        return SettingsApiFp(this.configuration).apiSettingsGetBadgesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetVisibilityGroups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiSettingsGetVisibilityGroupsPost(options?: RawAxiosRequestConfig) {
        return SettingsApiFp(this.configuration).apiSettingsGetVisibilityGroupsPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetLearningProviders
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiSettingsLearningProvidersGet(options?: RawAxiosRequestConfig) {
        return SettingsApiFp(this.configuration).apiSettingsLearningProvidersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} learningProviderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiSettingsLearningProvidersLearningProviderIdLearningContentsGet(learningProviderId: string, options?: RawAxiosRequestConfig) {
        return SettingsApiFp(this.configuration).apiSettingsLearningProvidersLearningProviderIdLearningContentsGet(learningProviderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary CreateLearningProviders
     * @param {LearningProviderDto} [learningProviderDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiSettingsLearningProvidersPost(learningProviderDto?: LearningProviderDto, options?: RawAxiosRequestConfig) {
        return SettingsApiFp(this.configuration).apiSettingsLearningProvidersPost(learningProviderDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary OverrideBadges
     * @param {Array<GamificationBadgeDto>} [gamificationBadgeDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiSettingsOverrideBadgesPost(gamificationBadgeDto?: Array<GamificationBadgeDto>, options?: RawAxiosRequestConfig) {
        return SettingsApiFp(this.configuration).apiSettingsOverrideBadgesPost(gamificationBadgeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary UploadCompanyLogo
     * @param {string} [contentType] 
     * @param {string} [contentDisposition] 
     * @param {{ [key: string]: Array<string>; }} [headers] 
     * @param {number} [length] 
     * @param {string} [name] 
     * @param {string} [fileName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiSettingsUploadCompanyLogoPost(contentType?: string, contentDisposition?: string, headers?: { [key: string]: Array<string>; }, length?: number, name?: string, fileName?: string, options?: RawAxiosRequestConfig) {
        return SettingsApiFp(this.configuration).apiSettingsUploadCompanyLogoPost(contentType, contentDisposition, headers, length, name, fileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} categoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public archiveCategory(categoryId: string, options?: RawAxiosRequestConfig) {
        return SettingsApiFp(this.configuration).archiveCategory(categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} categoryId 
     * @param {CourseCategoryDto} [courseCategoryDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public editCategory(categoryId: string, courseCategoryDto?: CourseCategoryDto, options?: RawAxiosRequestConfig) {
        return SettingsApiFp(this.configuration).editCategory(categoryId, courseCategoryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public getAllCategories(options?: RawAxiosRequestConfig) {
        return SettingsApiFp(this.configuration).getAllCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public getCompanyLogo(options?: RawAxiosRequestConfig) {
        return SettingsApiFp(this.configuration).getCompanyLogo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public getTimeZones(options?: RawAxiosRequestConfig) {
        return SettingsApiFp(this.configuration).getTimeZones(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<CourseCategoryDto>} [courseCategoryDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public overrideCategories(courseCategoryDto?: Array<CourseCategoryDto>, options?: RawAxiosRequestConfig) {
        return SettingsApiFp(this.configuration).overrideCategories(courseCategoryDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TenantApi - axios parameter creator
 * @export
 */
export const TenantApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} upnOrId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantGroupsUpnOrIdPhotoGet: async (upnOrId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'upnOrId' is not null or undefined
            assertParamExists('apiTenantGroupsUpnOrIdPhotoGet', 'upnOrId', upnOrId)
            const localVarPath = `/api/Tenant/Groups/{upnOrId}/Photo`
                .replace(`{${"upnOrId"}}`, encodeURIComponent(String(upnOrId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantSearchValueGet: async (value: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'value' is not null or undefined
            assertParamExists('apiTenantSearchValueGet', 'value', value)
            const localVarPath = `/api/Tenant/search/{value}`
                .replace(`{${"value"}}`, encodeURIComponent(String(value)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} upnOrId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantUsersUpnOrIdGet: async (upnOrId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'upnOrId' is not null or undefined
            assertParamExists('apiTenantUsersUpnOrIdGet', 'upnOrId', upnOrId)
            const localVarPath = `/api/Tenant/Users/{upnOrId}`
                .replace(`{${"upnOrId"}}`, encodeURIComponent(String(upnOrId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} upnOrId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantUsersUpnOrIdPhotoGet: async (upnOrId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'upnOrId' is not null or undefined
            assertParamExists('apiTenantUsersUpnOrIdPhotoGet', 'upnOrId', upnOrId)
            const localVarPath = `/api/Tenant/Users/{upnOrId}/Photo`
                .replace(`{${"upnOrId"}}`, encodeURIComponent(String(upnOrId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantApi - functional programming interface
 * @export
 */
export const TenantApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenantApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} upnOrId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantGroupsUpnOrIdPhotoGet(upnOrId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantGroupsUpnOrIdPhotoGet(upnOrId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantApi.apiTenantGroupsUpnOrIdPhotoGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantSearchValueGet(value: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiTenantSearchValueGet200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantSearchValueGet(value, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantApi.apiTenantSearchValueGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} upnOrId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantUsersUpnOrIdGet(upnOrId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantUsersUpnOrIdGet(upnOrId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantApi.apiTenantUsersUpnOrIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} upnOrId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantUsersUpnOrIdPhotoGet(upnOrId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantUsersUpnOrIdPhotoGet(upnOrId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantApi.apiTenantUsersUpnOrIdPhotoGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TenantApi - factory interface
 * @export
 */
export const TenantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenantApiFp(configuration)
    return {
        /**
         * 
         * @param {string} upnOrId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantGroupsUpnOrIdPhotoGet(upnOrId: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiTenantGroupsUpnOrIdPhotoGet(upnOrId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantSearchValueGet(value: string, options?: any): AxiosPromise<Array<ApiTenantSearchValueGet200ResponseInner>> {
            return localVarFp.apiTenantSearchValueGet(value, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} upnOrId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantUsersUpnOrIdGet(upnOrId: string, options?: any): AxiosPromise<UserDto> {
            return localVarFp.apiTenantUsersUpnOrIdGet(upnOrId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} upnOrId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantUsersUpnOrIdPhotoGet(upnOrId: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiTenantUsersUpnOrIdPhotoGet(upnOrId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TenantApi - object-oriented interface
 * @export
 * @class TenantApi
 * @extends {BaseAPI}
 */
export class TenantApi extends BaseAPI {
    /**
     * 
     * @param {string} upnOrId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public apiTenantGroupsUpnOrIdPhotoGet(upnOrId: string, options?: RawAxiosRequestConfig) {
        return TenantApiFp(this.configuration).apiTenantGroupsUpnOrIdPhotoGet(upnOrId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} value 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public apiTenantSearchValueGet(value: string, options?: RawAxiosRequestConfig) {
        return TenantApiFp(this.configuration).apiTenantSearchValueGet(value, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} upnOrId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public apiTenantUsersUpnOrIdGet(upnOrId: string, options?: RawAxiosRequestConfig) {
        return TenantApiFp(this.configuration).apiTenantUsersUpnOrIdGet(upnOrId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} upnOrId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public apiTenantUsersUpnOrIdPhotoGet(upnOrId: string, options?: RawAxiosRequestConfig) {
        return TenantApiFp(this.configuration).apiTenantUsersUpnOrIdPhotoGet(upnOrId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserCoursesApi - axios parameter creator
 * @export
 */
export const UserCoursesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCourse: async (uuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('getUserCourse', 'uuid', uuid)
            const localVarPath = `/api/UserCourses/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCourseByLang: async (uuid: string, lang: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('getUserCourseByLang', 'uuid', uuid)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('getUserCourseByLang', 'lang', lang)
            const localVarPath = `/api/UserCourses/{uuid}/{lang}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)))
                .replace(`{${"lang"}}`, encodeURIComponent(String(lang)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lang 
         * @param {string} lessonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserLessonAttachment: async (courseId: string, lang: string, lessonId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('getUserLessonAttachment', 'courseId', courseId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('getUserLessonAttachment', 'lang', lang)
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('getUserLessonAttachment', 'lessonId', lessonId)
            const localVarPath = `/api/UserCourses/{courseId}/{lang}/lessons/{lessonId}/attachment`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"lang"}}`, encodeURIComponent(String(lang)))
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lang 
         * @param {string} lessonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserLessonAudio: async (courseId: string, lang: string, lessonId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('getUserLessonAudio', 'courseId', courseId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('getUserLessonAudio', 'lang', lang)
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('getUserLessonAudio', 'lessonId', lessonId)
            const localVarPath = `/api/UserCourses/{courseId}/{lang}/lessons/{lessonId}/audio`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"lang"}}`, encodeURIComponent(String(lang)))
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isAssigned: async (uuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('isAssigned', 'uuid', uuid)
            const localVarPath = `/api/UserCourses/{uuid}/IsAssigned`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserCoursesApi - functional programming interface
 * @export
 */
export const UserCoursesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserCoursesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserCourse(uuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseV2Dto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserCourse(uuid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserCoursesApi.getUserCourse']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserCourseByLang(uuid: string, lang: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseV2Dto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserCourseByLang(uuid, lang, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserCoursesApi.getUserCourseByLang']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lang 
         * @param {string} lessonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserLessonAttachment(courseId: string, lang: string, lessonId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserLessonAttachment(courseId, lang, lessonId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserCoursesApi.getUserLessonAttachment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lang 
         * @param {string} lessonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserLessonAudio(courseId: string, lang: string, lessonId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserLessonAudio(courseId, lang, lessonId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserCoursesApi.getUserLessonAudio']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isAssigned(uuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isAssigned(uuid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserCoursesApi.isAssigned']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserCoursesApi - factory interface
 * @export
 */
export const UserCoursesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserCoursesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCourse(uuid: string, options?: any): AxiosPromise<CourseV2Dto> {
            return localVarFp.getUserCourse(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCourseByLang(uuid: string, lang: string, options?: any): AxiosPromise<CourseV2Dto> {
            return localVarFp.getUserCourseByLang(uuid, lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lang 
         * @param {string} lessonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserLessonAttachment(courseId: string, lang: string, lessonId: string, options?: any): AxiosPromise<FileDto> {
            return localVarFp.getUserLessonAttachment(courseId, lang, lessonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {string} lang 
         * @param {string} lessonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserLessonAudio(courseId: string, lang: string, lessonId: string, options?: any): AxiosPromise<void> {
            return localVarFp.getUserLessonAudio(courseId, lang, lessonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isAssigned(uuid: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.isAssigned(uuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserCoursesApi - object-oriented interface
 * @export
 * @class UserCoursesApi
 * @extends {BaseAPI}
 */
export class UserCoursesApi extends BaseAPI {
    /**
     * 
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserCoursesApi
     */
    public getUserCourse(uuid: string, options?: RawAxiosRequestConfig) {
        return UserCoursesApiFp(this.configuration).getUserCourse(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {string} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserCoursesApi
     */
    public getUserCourseByLang(uuid: string, lang: string, options?: RawAxiosRequestConfig) {
        return UserCoursesApiFp(this.configuration).getUserCourseByLang(uuid, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {string} lang 
     * @param {string} lessonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserCoursesApi
     */
    public getUserLessonAttachment(courseId: string, lang: string, lessonId: string, options?: RawAxiosRequestConfig) {
        return UserCoursesApiFp(this.configuration).getUserLessonAttachment(courseId, lang, lessonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {string} lang 
     * @param {string} lessonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserCoursesApi
     */
    public getUserLessonAudio(courseId: string, lang: string, lessonId: string, options?: RawAxiosRequestConfig) {
        return UserCoursesApiFp(this.configuration).getUserLessonAudio(courseId, lang, lessonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserCoursesApi
     */
    public isAssigned(uuid: string, options?: RawAxiosRequestConfig) {
        return UserCoursesApiFp(this.configuration).isAssigned(uuid, options).then((request) => request(this.axios, this.basePath));
    }
}



