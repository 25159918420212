import { getClassNames } from "./MLCollection.classNames";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { ANIMATION_DURATION } from "utils/constants/styles";
import { useEffect, useRef, useState } from "react";
import { remToPx } from "utils/helpers/helpers";
import MLItem from "./MLItem/MLItem";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragStartEvent,
  DragOverlay,
  DragOverEvent,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";

interface IMLCollectionProps {
  items: { id: string | number; value: string; secondaryValue?: string; active?: boolean; ref: React.RefObject<HTMLDivElement> }[];
  setItems: React.Dispatch<React.SetStateAction<{
    id: string | number;
    value: string;
    secondaryValue?: string;
    active?: boolean;
    ref: React.RefObject<HTMLDivElement>;
  }[]>>;
  title: string;
  disabled?: boolean;
  disabledIndex?: number;
  draggable?: boolean;
  maxHeight?: boolean;
  withToggle?: boolean;
  nonEditable?: boolean;
  selectedIndex?: number;
  onClick?: (index: number) => void;
  prefix?: string;
  suffix?: string;
  secondarySuffix?: string;
  onDragEnd?: () => void;
  onDelete?: (id: string | number) => void;
  onToggle?: (id: string | number, checked: boolean) => void;
}

const MLCollection: React.FC<IMLCollectionProps> = ({
    items,
    setItems,
    title,
    disabled,
    disabledIndex,
    draggable,
    maxHeight,
    withToggle,
    nonEditable,
    selectedIndex,
    onClick,
    prefix,
    suffix,
    secondarySuffix,
    onDragEnd,
    onDelete,
    onToggle,
  }) => {
  const [height, setHeight] = useState<string | number>('auto');
  const containerRef = useRef<HTMLDivElement>(null);
  const [activeId, setActiveId] = useState<string | number | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    if (active) setActiveId(active.id.toString());
  };

  const handleDragOver = (event: DragOverEvent) => {
    const { active, over } = event;

    if (active.id.toString() !== over?.id.toString()) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.id.toString() === active.id.toString());
        const newIndex = items.findIndex((item) => item.id.toString() === over?.id.toString());

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }

  const handleDragEnd = () => {
    setActiveId(null);
    onDragEnd && onDragEnd();
  };

  // const onToggle = (id: string | number, checked: boolean) => {
  //   const index = items.findIndex((item) => item.id === id);
  //   setItems(prevItems => 
  //     prevItems.map((item, i) => 
  //       i === index 
  //         ? { ...item, active: checked } 
  //         : item
  //     )
  //   );
  // };
  const handleDelete = (id: string | number) => {
    onDelete && onDelete(id);
  }

  const onChange = (id: string | number, newValue: string) => {
    const index = items.findIndex((item) => item.id === id);
    setItems(prevItems => 
      prevItems.map((item, i) => 
        i === index 
          ? { ...item, value: newValue || '' } 
          : item
      )
    );
  };

  const onSecondaryChange = (id: string | number, newSecondaryValue: string) => {
    const index = items.findIndex((item) => item.id === id);
    setItems(prevItems => 
      prevItems.map((item, i) => 
        i === index 
          ? { ...item, secondaryValue: newSecondaryValue || '' } 
          : item
      )
    );
  };

  useEffect(() => {
    const lastItem = items[items.length - 1]?.ref?.current;
    if (containerRef.current) {
      const itemsHeight = (lastItem?.clientHeight || remToPx(3.4)) * items.length;
      const gaps = remToPx(0.5) * (items.length - 1);
      setHeight(itemsHeight + gaps);

      if (!((containerRef.current).style.maxHeight)) {
        (containerRef.current).style.maxHeight = `${((lastItem?.clientHeight || remToPx(3.4)) * 4) + (remToPx(0.5) * 3)}px`;
      }
      setTimeout(() => {
        if (containerRef.current) {
          if (items.length >= 5) (containerRef.current).style.overflowY = 'scroll';
          else (containerRef.current).style.overflowY = 'hidden';
        }
      }, ANIMATION_DURATION);
    }
  }, [items]);
  
  const classes = getClassNames(!!disabled);

  const Group = () => {
    return (
      <DndContext 
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragOver={handleDragOver}
      >
        <SortableContext 
          items={items.map(item => item.id.toString())}
        >
          <TransitionGroup component={null}>
            {items.map((item, index) => (
              <CSSTransition
                key={item.id}
                timeout={ANIMATION_DURATION}
                classNames={{
                  enter: classes.itemEnter,
                  enterActive: classes.itemEnterActive,
                  exit: classes.itemExit,
                  exitActive: classes.itemExitActive,
                }}
                nodeRef={item.ref}
              >
                <div
                  ref={item.ref}
                  className={classes.rowContainer}
                >
                  <MLItem
                    item={item}
                    disabled={disabled}
                    deleteDisabled={disabledIndex === index}
                    draggable={draggable}
                    withToggle={withToggle}
                    nonEditable={nonEditable}
                    selected={selectedIndex === index}
                    onClick={onClick ? () => onClick(index) : undefined}
                    onDelete={handleDelete}
                    onToggle={onToggle}
                    onChange={onChange}
                    onSecondaryChange={onSecondaryChange}
                    prefix={prefix}
                    suffix={suffix}
                    secondarySuffix={secondarySuffix}
                  />
                </div>
              </CSSTransition>
            ))}
          </TransitionGroup>
        </SortableContext>
        <DragOverlay>
          {(activeId !== null && activeId !== undefined) ? (
            <MLItem
              item={items?.find(item => item.id.toString() === activeId.toString())}
              disabled={disabled}
              deleteDisabled={disabledIndex === items?.findIndex(item => item.id.toString() === activeId.toString())}
              draggable={draggable}
              withToggle={withToggle}
              nonEditable={nonEditable}
              selected={false}
              onDelete={handleDelete}
              onToggle={onToggle}
              onChange={onChange}
              onSecondaryChange={onSecondaryChange}
            />
          ) : null}
        </DragOverlay>
      </DndContext>
    );
  };

  return (
    <div className={classes.collection}>
      <h5 className={classes.collectionTitle}>{title}</h5>
      {maxHeight !== undefined ? (
        <div
          className={classes.collectionContainer}
          ref={containerRef}
          style={{ height }}
        >
          {Group()}
        </div>
      ) : (
        <>{Group()}</>
      )}
    </div>
  );
};

export default MLCollection;