import { NavLink, useLocation } from 'react-router-dom';
import { getClassNames } from './MLMenu.classNames';
import CreateIcon from 'assets/ml-icons/Menu/Create/create';
import LearningsIcon from 'assets/ml-icons/Menu/Learnings/learnings';
import { useContext, useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { FiltersContext } from '../../../contexts/filters-context';
import SettingsIcon from 'assets/ml-icons/Menu/Settings/Settings';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
;

interface IMenuProps {
}


export default function MLMenu(props: IMenuProps) {
  const { roles } = useSelector((state: RootState) => state.account);
  const location = useLocation();

  const [showMenu] = useState<boolean>(true);
  const [expandedMenu, setExpandedMenu] = useState<boolean>(location.pathname === '/learnings');
  const classes = getClassNames(showMenu, expandedMenu);

  const filters = useContext(FiltersContext);

  const menuRef = useRef<HTMLDivElement>(null);
  const filtersRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setExpandedMenu(location.pathname === '/learnings')
  }, [location.pathname]);

  return (
    <CSSTransition
      timeout={500}
      in={showMenu}
      nodeRef={menuRef}
      key={'microlearning-menu'}
      classNames={{
        enter: classes.navbarEnter,
        enterActive: classes.navbarEnterActive,
        enterDone: classes.navbarEnterDone,
        exit: classes.navbarExit,
        exitActive: classes.navbarExitActive,
        appearDone: classes.navbarEnterDone,
      }}
      appear
      unmountOnExit
    >
      <div ref={menuRef} className={classes.menuContainer}>

        <div className={classes.navbarContainer}>
          <div className={classes.logoContainer}>
            <img
              className={classes.microlearningIcon}
              src={require('assets/ml-icons/microlearning.png')}
              alt="microlearning"
            />
            <p style={{ fontWeight: 700, fontSize: '1.2rem' }}>Microlearning</p>
          </div>

          <div className={classes.itemsContainer}>
          <div className={classes.primaryItems}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <NavLink
              className={({ isActive }) => [
                classes.link,
                isActive ? classes.activeLink : '',
              ].join(" ")}
              to="/learnings"
              end
              title='Learnings'
            >
              <LearningsIcon />
            </NavLink>

            <CSSTransition
              timeout={500}
              in={location.pathname === '/learnings'}
              nodeRef={filtersRef}
              key={'filters'}
              classNames={{
                enter: classes.filtersEnter,
                enterActive: classes.filtersEnterActive,
                enterDone: classes.filtersEnterDone,
                exit: classes.filtersExit,
                exitActive: classes.filtersExitActive,
              }}
              unmountOnExit
            >
              <div ref={filtersRef} className={classes.filtersContainer}>
                <ul className={classes.filters}>
                  <li className={`${classes.filterItem} ${filters.learnings.selectedFilter === 0 && classes.selected}`} onClick={() => { filters.learnings.updateSelectedFilter(0) }}>Company learnings</li>
                  <li className={`${classes.filterItem} ${filters.learnings.selectedFilter === 1 && classes.selected}`} onClick={() => { filters.learnings.updateSelectedFilter(1) }}>My learnings</li>
                  <li className={`${classes.filterItem} ${filters.learnings.selectedFilter === 2 && classes.selected}`} onClick={() => { filters.learnings.updateSelectedFilter(2) }}>Running learnings</li>
                </ul>
              </div>
            </CSSTransition>
          </div>

          <NavLink
            className={({ isActive }) => [
              classes.link,
              isActive ? classes.activeLink : '',
            ].join(" ")}
            to="/create"
            title='Create'
          >
              <CreateIcon />
          </NavLink>
          </div>

          <div className={classes.secondaryItems}>
            {roles.includes('Admin') && <NavLink
              className={({ isActive }) => [
                classes.link,
                isActive ? classes.activeLink : '',
              ].join(" ")}
              to="/settings"
              end
              title='Settings'
            >
              <SettingsIcon />
            </NavLink>}
          </div>
        </div>
        </div>
      </div>
    </CSSTransition>
  );
}