// import react
import { useRef } from "react";

// import styles
import { getClassNames } from "./NewSchedule.classNames";

// import data
import Recipient from "./components/Recipient/Recipient";
import DateAndTime from "./components/DateTime/DateTime";
import Frequency from "./components/Frequency/Frequency";
import MLForm from "common/ml-components/MLForm";
import { CSSTransition } from "react-transition-group";
import { ANIMATION_DURATION } from "utils/constants/styles";
import { IUser } from "common/models/LearningDetails/user";
import { DateTime } from "luxon";
import { ScheduleDto, ScheduleStatusDto } from "api-client";
import { getSelectedTimeZone, getTime, windowsId2Iana } from "utils/helpers/helpers";
import { RootState, useAppDispatch } from "store/store";
import { addSchedule, editSchedule } from "store/slices/courses";

import _ from "lodash";
import { DefaultValues, SubmitHandler } from "react-hook-form";
import { useSelector } from "react-redux";

export interface IScheduleData {
  recipient: [IUser] | null | undefined;
  startDate: Date | undefined;
  time: Date | undefined;
  timeZone: string;
  frequency: string;
}

interface INewScheduleProps {
  show: boolean;
  onDismiss: () => void;
  scheduleItem: IScheduleData | undefined;
}

const NewSchedule: React.FC<INewScheduleProps> = ({ show, onDismiss, scheduleItem }) => {
  const { timeZones } = useSelector((state: RootState) => state.settings);
  const dispatch = useAppDispatch();
  const newScheduleRef = useRef<HTMLDivElement>(null);

  const defaultValues: IScheduleData = scheduleItem || {
    recipient: null,
    startDate: new Date(),
    time: getTime().toJSDate(),
    timeZone: getSelectedTimeZone(timeZones),
    frequency: '',
  }

  const classes = getClassNames();

  const onSubmit = async (data: IScheduleData) => {
    if (!data.startDate) return;
    const startDate = DateTime.fromJSDate(data.startDate).set({hour: data.time?.getHours(), minute: data.time?.getMinutes()})
    .setZone(windowsId2Iana(data.timeZone)).toUTC().toISO()?.toString();

    const recipient = data.recipient?.[0];

    const newSchedule: ScheduleDto = {
      recipient: {
        id: recipient?.key?.toString() || '',
        displayName: recipient?.text,
        type: recipient?.userType,
        profilePicture: ''
      },
      startDate: startDate ? startDate : '',
      frequency: Number(data.frequency) || 0,
      status: ScheduleStatusDto.Draft,
      timeZoneId: data.timeZone,
    };

    try {
      scheduleItem ? 
        await dispatch(editSchedule(newSchedule)).unwrap() :
        await dispatch(addSchedule(newSchedule)).unwrap();
      onDismiss();
    } catch (err) {
      console.error(err);
    }
  };

  const compareData = (values: any) => {
    if (scheduleItem) {
      console.log(values, scheduleItem);
      return !_.isEqual(values, scheduleItem);
    } else return true;
  };

  return (
    <CSSTransition
      nodeRef={newScheduleRef}
      timeout={ANIMATION_DURATION}
      in={show}
      classNames={{
        enter: classes.enter,
        enterActive: classes.enterActive,
        enterDone: classes.enterDone,
        exit: classes.exit,
        exitActive: classes.exitActive,
        exitDone: classes.exitDone,
      }}
      unmountOnExit
    >
      <div ref={newScheduleRef} className={classes.newSchedule}>
        {/* Here I need to pass props with 'as' to avoid the TypeScript error:
        "Type instantiation is excessively deep and possibly infinite." */}
        <MLForm<IScheduleData>
          defaultValues={defaultValues as DefaultValues<IScheduleData>}
          onSubmit={onSubmit as SubmitHandler<IScheduleData>}
          onDismiss={onDismiss}
          compareData={compareData as (data: IScheduleData) => boolean}
          gap="0rem"
          overflowHidden
        >
          <div className={classes.addScheduleFormContainer}>
            <Recipient />
            <DateAndTime />
            <Frequency />
          </div>
        </MLForm>
      </div>
    </CSSTransition>
  )
};

export default NewSchedule;