import { createRef } from "react";
import { getClassNames } from "./Router.classNames";

import { RouteObject, createBrowserRouter, useLocation, useOutlet, matchPath, Navigate } from "react-router-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { getTheme } from "@fluentui/react";

import AppLayout from "common/layouts/AppLayout/AppLayout";
import Create from "pages/Create";
import Learnings from "pages/Learnings/Learnings";
import Learning from "pages/Learnings/Learning";
import Settings from "pages/Settings";

import { ANIMATION_DURATION } from "utils/constants/styles";
import Landing from "pages/Landing/Landing";

type CustomRouteObject = RouteObject & {
  name: string;
  nodeRef: React.RefObject<HTMLDivElement>;
}

export const routes: CustomRouteObject[] = [
  {
    path: '/learnings',
    name: 'Learnings',
    element: <Learnings />,
    nodeRef: createRef<HTMLDivElement>(),
  },
  {
    path: '/learnings/:id/:lang?',
    name: 'Learning',
    element: <Learning />,
    nodeRef: createRef<HTMLDivElement>(),
  },
  { 
    path: '/create',
    name: 'Create',
    element: <Create />,
    nodeRef: createRef<HTMLDivElement>(),
  },
  { 
    path: '/settings',
    name: 'Settings',
    element: <Settings />,
    nodeRef: createRef<HTMLDivElement>(),
  },
  { 
    path: '/landing',
    name: 'Landing',
    element: <Landing />,
    nodeRef: createRef<HTMLDivElement>(),
  }
];



const  Router: React.FC = () => {
  const classes = getClassNames();

  const location = useLocation();
  const currentOutlet = useOutlet();

  const { nodeRef, name } =
    (routes.find((route) => matchPath(route.path || '', location.pathname)) ?? {}) as CustomRouteObject;

  const theme = getTheme();
  if (name === 'Create') document.body.style.backgroundColor = theme.palette.themeLighter;
  if (name === 'Settings') document.body.style.backgroundColor = theme.palette.themeLighter;
  if (name === 'Learnings') document.body.style.backgroundColor = theme.palette.white;
  if (name === 'Learning') document.body.style.backgroundColor = theme.palette.neutralLighter;

  return (
    <AppLayout>
      <SwitchTransition>
        <CSSTransition
          key={location.pathname}
          nodeRef={nodeRef}
          timeout={ANIMATION_DURATION}
          classNames={{
            enter: classes.pageEnter,
            enterActive: classes.pageEnterActive,
            exit: classes.pageExit,
            exitActive: classes.pageExitActive,
          }}
          unmountOnExit
        >
          <div ref={nodeRef} className={classes.route}>
            {currentOutlet}
          </div>
          
        </CSSTransition>
      </SwitchTransition>
    </AppLayout>
  )
};

export default Router;

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Router />,
    children: [
      {
        index: true,
        element: <Navigate to="/learnings" replace />,
      },
      ...routes.map((route) => ({
      index: route.path === '/',
      path: route.path === '/' ? '/learnings' : route.path,
      element: route.element,
    }))],
  },
]);