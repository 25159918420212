import { useFormContext } from "react-hook-form";
import { createRef, useState } from "react";
import MLTextField from "common/ml-components/inputs/MLTextField";
import MLDropdown, { DropdownStyles } from "common/ml-components/inputs/MLDropdown";
import MLCollection from "common/ml-components/MLList/MLCollection/MLCollection";
import MLNewItem from "common/ml-components/MLList/MLNewItem/MLNewItem";

interface IQuizLessonProps {
  lessonTypeChanged: boolean;
}

const QuizLesson: React.FC<IQuizLessonProps> = ({ lessonTypeChanged }) => {
  const { getValues, setValue, watch } = useFormContext();

  const fields = getValues('lesson.options');
  const [options, setOptions] =
    useState<{ id: string | number; value: string; ref: React.RefObject<HTMLDivElement> }[]>(() => (
      fields.map((field: { id: string | number; value: string; }) => ({
        id: field.id,
        value: field.value,
        ref: createRef<HTMLDivElement>(),
      }))
    ));

  const correctAnswerId = watch('lesson.correctAnswerId');

  const onDragEnd = () => {
    setValue('lesson.options', options.map(opt => ({ id: opt.id, value: opt.value })), { shouldDirty: true });
  };

  const onAdd = (value: string) => {
    const maxId = options.length > 0 ? Math.max(...options.map(item => item.id as number)) : -1;
    const newItem = {
      id: maxId + 1,
      value
    };
    setOptions([...options, { ...newItem, ref: createRef<HTMLDivElement>() }]);
    setValue('lesson.options', [...options, { ...newItem }], { shouldDirty: true });
  };

  const onDelete = (id: string | number) => {
    const newItems = [...options];
    const index = newItems.findIndex((item) => item.id === id);
    newItems.splice(index, 1);
    setOptions(newItems);
    setValue('lesson.options', newItems, { shouldDirty: true });
  };

  return (
    <>
      <MLTextField
        name="lesson.title"
        label="Title"
        multiline
        autoAdjustHeight
        resizable={false}
        disabled={lessonTypeChanged}
        formRequired
      />
      <MLTextField
        name="lesson.question"
        label="Question"
        multiline
        autoAdjustHeight
        resizable={false}
        disabled={lessonTypeChanged}
        formRequired
      />
      <MLCollection
        items={options}
        setItems={setOptions}
        title="Answers"
        disabled={lessonTypeChanged}
        disabledIndex={options.findIndex((opt: any) => opt.id === correctAnswerId)}
        draggable
        onDragEnd={onDragEnd}
        onDelete={onDelete}
      />
      <MLNewItem
        items={options}
        setItems={setOptions}
        onAdd={onAdd}
      />
      <MLDropdown
        name='lesson.correctAnswerId'
        label="Correct answer"
        dropdownWidth={300}
        paddingRight="5rem"
        dropdownStyle={DropdownStyles.COLORED}
        options={options.map((option: any) => ({ key: option.id, text: option.value }))}
      />
      <MLTextField
        name="lesson.explanationCorrectAnswer"
        label='Correct answer explanation'
        multiline
        autoAdjustHeight
        resizable={false}
        disabled={lessonTypeChanged}
      />
    </>
  );
};

export default QuizLesson;
